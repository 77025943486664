<template>
  <div class="user-feedback">
    <div class="userinfo">
      <span class="name">{{ info.patientName }}</span>
      <span class="gender">{{ info.gender }}</span>
      <span class="age">{{ info.patientAge }}岁</span>
      <span class="phone">{{ info.cellphone }}</span>
    </div>

    <div class="tags">
      <Tag v-for="(item, index) in labelData?.patientLabels" type="border" :key="index" :color="typeList[item.labelType]">{{ common.valueToLabel(labelData.list, item.labelId) }}</Tag>
    </div>
    <template v-if="activeItem.messageId">
      <div class="question-info2">
        <span>患者反馈：</span>
        {{ activeItem.feedbackQuestion }}
      </div>
      <Input
        class="question"
        v-model="replyMessage"
        placeholder="请输入回复患者的内容"
        type="textarea"
        clearable
        :rows="4"
      ></Input>
      <div class="btn-box">
        <Button :disabled="!replyMessage.trim()" type="primary" @click="submit"
          >回复</Button
        >
      </div>
    </template>
    <div class="header">反馈记录</div>
    <div class="question-list">
      <div class="q-l-item" v-for="(item, index) in questionList" :key="index">
        <div class="question-info">{{ item.feedbackQuestion }}</div>
        <div class="answer" v-if="item.replyMessage">
          <span>回复：</span>{{ item.replyMessage }}
        </div>
        <div class="q-l-i-footer">
          <span v-if="item.feedbackDate"
            >反馈时间：{{ item.feedbackDate }}</span
          >
          <span v-if="item.replyDate">回复时间：{{ item.replyDate }}</span>
          <span v-if="item.doctorName">回复医生：{{ item.doctorName }}</span>
          <span v-if="item.hospitalName"
            >回复医馆：{{ item.hospitalName }}</span
          >
          <Button
            v-if="isShowEdit(item)"
            type="text"
            size="small"
            @click="edit(item)"
            >修改回复</Button
          >
        </div>
      </div>
      <empty v-if="!questionList.length">暂无数据</empty>
    </div>
    <div class="footer">
      <Page
        layout="prev, pager, next"
        :total="total"
        @on-change="pageChange"
        size="small"
      >
      </Page>
    </div>

    <Modal v-model="editWin" title="回复内容" @on-cancel="cancel">
      <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        label-position="top"
      >
        <FormItem label="回复内容" prop="replyMessage">
          <Input
            v-model="formValidate.replyMessage"
            placeholder="请输入回复患者的内容"
            type="textarea"
            :rows="5"
          ></Input>
        </FormItem>
      </Form>

      <template slot="footer">
        <Button @click="cancel">取消</Button>
        <Button type="primary" @click="editSub">确定</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    info(newValue, oldValue) {
      this.init();
    },
  },
  data() {
    return {
      labelData: [],
      editWin: false,
      replyMessage: "",
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      questionList: [],
      activeItem: {},
      editActiveItem: {},
      formValidate: {
        replyMessage: "",
      },
      ruleValidate: {
        replyMessage: [
          {
            required: true,
            message: "请输入回复患者的内容",
            trigger: "blur",
          },
        ],
      },
      typeList: [
        "",
        "primary",
        "success",
        "error",
        "warning",
      ],
    };
  },
  methods: {
    getLabelData() {
      this.api2("labelData", {
        patientCode: this.info.patientCode,
      }).then((res) => {
        let r = res.result;
        r.list = r.labelTypes.reduce((sum, e) => {
          e.labels.forEach((i) => {
            i.label = i.labelName;
            i.value = i.labelId;
          });
          return sum.concat(e.labels);
        }, []);
        this.labelData = r;
      });
    },
    isShowEdit(item) {
      return (
        item.replyMessage &&
        dayjs(item.replyDate).format("YYYY-MM-DD") ===
          dayjs().format("YYYY-MM-DD")
      );
    },
    cancel() {
      this.editWin = false;
    },
    edit(item) {
      this.editActiveItem = item;
      this.formValidate.replyMessage = item.replyMessage;
      this.editWin = true;
    },
    getData() {
      let obj = {
        patientCode: this.info.patientCode,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      };
      this.api2("getFeedbackMessageListByPage", obj).then((res) => {
        let list = res.result;
        if (list.length > 0 && !list[0].replyMessage) {
          this.activeItem = list[0];
          this.questionList = list.slice(1, list.length);
        } else {
          this.activeItem = {};
          this.questionList = list;
        }
        this.total = res.total;
      });
    },
    pageChange(index) {
      this.pageIndex = index;
      this.getData();
    },
    editSub() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.replayFeedbackMessage(
            {
              messageId: this.editActiveItem.messageId,
              replyMessage: this.formValidate.replyMessage,
            },
            true
          );
        } else {
          this.$Message.error("请将表单填写完整");
        }
      });
    },
    submit() {
      this.replayFeedbackMessage(
        {
          messageId: this.activeItem.messageId,
          replyMessage: this.replyMessage,
        },
        false
      );
    },
    replayFeedbackMessage(obj, isEdit) {
      this.api2("replayFeedbackMessage", obj).then((res) => {
        this.replyMessage = "";
        this.formValidate.replyMessage = "";
        this.$Message.success("回复成功");
        this.editWin = false;
        this.getData();
        if (!isEdit) {
          this.$emit("success");
        }
      });
    },
    init() {
      this.pageIndex = 1;
      this.getData();
      this.getLabelData();
    },
  },
  created() {
    this.init();
  },
};
</script>


<style scoped lang="scss">
.user-feedback {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  .question {
    margin-bottom: 20px;
  }
  .btn-box {
    text-align: right;
  }
  .question-list {
    height: 100%;
    overflow-y: auto;
  }
  .q-l-item {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    line-height: 2em;
    .answer {
      background-color: #f5f5f5;
      padding: 5px 15px;
      border-radius: 5px;
      margin: 8px 0;
      span {
      }
    }
  }
  .q-l-i-footer {
    font-size: 12px;
    & > span {
      padding-right: 15px;
      opacity: 0.6;
    }
    .ivu-btn {
      float: right;
      color: #2d8cf0;
    }
  }
  .question-info2 {
    margin-bottom: 15px;
    span {
      font-weight: bold;
    }
  }
  .tags {
    margin-bottom: 15px;
  }
  .userinfo {
    margin-bottom: 15px;
    font-weight: bold;
    span {
      padding-right: 15px;
      &.name {
        font-size: 20px;
      }
    }
  }
}
</style>