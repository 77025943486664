<template>
  <div class="user-list">
    <div class="header">
      <div class="u_tabs">
        <div
          class="u_tabs_item"
          @click="clickTabs(item)"
          :class="{ active: item.active }"
          v-for="(item, index) in tabs"
          :key="index"
        >
          <span class="label">{{ item.label }}{{item.count}}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div
        class="item"
        :class="{ active: item.patientCode == patientCode}"
        v-for="(item, index) in list"
        :key="index"
        @click="goDetail(item)"
      >
        <i v-if="item.gender == '女'" class="iconfont icon-morennvtouxiang"></i>
        <i v-else class="iconfont icon-morennantouxiang"></i>
        <div class="name">{{ item.patientName }}</div>
        <div class="age">{{ item.patientAge }}岁</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      patientCode: "", 
      list: [],
      tabs: [
        {
          label: "待回复",
          value: 0,
          active: true,
          count: 0,
        },
        {
          label: "已回复",
          value: 1,
          active: false,
          count: 0,
        },
      ],
    };
  },
  methods: {
    changeCount(){
      this.tabs[0].count -= 1; 
      this.tabs[1].count += 1; 
      this.$store.commit("subtractMessageCount");
    },
    goDetail(item) {
      this.patientCode = item.patientCode;
      this.$emit("change", item);
    },
    clickTabs(item) {
      this.tabs.forEach((i) => (i.active = false));
      item.active = true;
      this.getData();
    },
    getData() {
      return new Promise((resolve, reject) => {
        let item = this.tabs.find((i) => i.active);
        if (item) {
          this.api2("getFeedbackMessagePatientList", {
            replayFlag: item.value,
          }).then((res) => {
            item.count = res.result.length;
            if(!item.value) {
              this.$store.commit("setMessageCount", item.count);
            }
            this.list = res.result;
            if(this.list.length > 0){
              this.goDetail(this.list[0])
            }
            resolve(res)
          }).catch(reject);
        }else{
          reject()
        }
      })
    },
    setCount(){
      let item = this.tabs.find((i) => !i.active);
      if (item) {
        this.api2("getFeedbackMessagePatientList", {
          replayFlag: item.value,
        }).then((res) => {
          item.count = res.result.length;
        });
      }
    },
  },
  created() {
    this.getData().then(() => {
      this.setCount();
    })
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  .item {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    & > * {
      padding-right: 15px;
    }
    &.active {
      background: #f5f5f5;
    }
  }
  .u_tabs {
    display: flex;
    padding-bottom: 15px;

    &_item {
      width: 100%;
      cursor: pointer;

      &.active .label {
        border-bottom: 2px solid #853a2f;
      }

      .label {
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
      }

      .ivu-badge-count {
        right: -10px;
        line-height: 14px;
        height: 16px;
        padding: 0 4px;
        min-width: 10px;
      }
    }
  }
  .iconfont {
    padding-right: 8px;

    &.icon-morennvtouxiang {
      color: #ff00b3;
    }

    &.icon-morennantouxiang {
      color: blue;
    }
  }
}
</style>