import axios from 'axios'
import store from '../store/index';
import iView from 'view-design'
import Aes from '@/utils/Aes'

const service = axios.create({
    baseURL: config.newapi,
    timeout: 500000
})

iView.Message.config({
    duration: 5
});


//手相接口调用失败信息
let errorInfo = {
    1: '请求中未包含params参数',
    2: "params字符串解密失败",
    3: "params载入失败，json格式错误",
    4: "params实例中的appId、timestamp、picId、apiUrl、userdata键值读取错误",
    5: "预测结果字符串加密失败",
    6: "把预测结果post到慈脉API时发生错误",
    7: "慈脉API返回success为False",
    8: "请求中未包含multipartFiles",
    9: "慈脉API的返回中未包含success参数",
    10: "算法预测失败、判定为异常样本",
}

const CancelToken = axios.CancelToken;
window.cancelList = {};

service.interceptors.request.use(
    config => {
        let urls = config.url.split("/");
        let name = urls[urls.length - 1]
        // if (window.cancelList[name] && name != "getWideUseList" && name != "getSungoData" ) {
        if (window.cancelList[name] && !["getWideUseList", "getSungoData", "getFeedbackMessagePatientList"].includes(name) ) {
            window.cancelList[name]();
        }
        // 配置当前的cancelToken 保存cancel
        config.cancelToken = new CancelToken((c) => {
            window.cancelList[name] = c;
        })

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data;
        if (!res.success) {
            if ((res.hasOwnProperty('meta') && res.meta.code == 200) || res.handdata) {
                return res;
            } else if (res.info) { //OML手相接口调用失败
                store.commit('closeSpin')
                let messages = errorInfo[res.info] || "手相接口调用失败";

                store.commit("sendLog", {
                    apiName: response.config.url,
                    outParams: res,
                    remarks: "手相接口返回错误"
                });

                iView.Message.error(messages)
                return Promise.reject(messages)
            } else {
                let messages = res.error.messages
                try {
                    messages = Aes.decrypt(messages)
                } catch (error) {
                    console.log(error);
                }

                store.commit("sendLog", {
                    apiName: response.config.url,
                    outParams: res,
                    remarks: "接口返回错误"
                });

                if (res.error.code == 11) {
                    iView.Message.error(messages + '，即将跳转到登录页面!')
                    setTimeout(() => {
                        store.commit("logOut");
                    }, 3000);
                } else {
                    store.commit('closeSpin')
                    iView.Message.error(messages || 'Errow')
                    return Promise.reject(messages)
                }
            }
        } else {
            if (res.result) {
                try {
                    res.result = Aes.decrypt(res.result);
                } catch (error) { }
            } else {
                if (res.encrypt) {
                    try {
                        res.result = Aes.decrypt(res.encrypt)
                    } catch (error) {
                        res.result = res.encrypt;
                    }
                } 
            }
            if (config.debug) console.log(response.config.url, JSON.parse(JSON.stringify(res)));

            return res;
        }
    },
    error => {
        if (axios.isCancel(error)) {
            return Promise.reject("已取消")
        } else {
            store.commit('closeSpin')
            iView.Message.error('未获取到服务器的响应')
            return Promise.reject(error)
        }
    }
)

export default service