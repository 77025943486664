<template>
  <Modal
    fullscreen
    :closable="false"
    :mask-closable="false"
    footer-hide
    class="hand2_con"
    v-model="showCamera"
    @on-cancel="cancel"
  >
    <div class="h-body" :class="{ hide: !isPic }">
      <div class="h-left">
        <div class="h-title">
          <i class="iconfont icon-shouzhang"></i>
          <span class="label">手相解读</span>
        </div>
        <div class="h-menu">
          <div
            @click="selectMenuItem(item, index)"
            class="h-menu-item"
            :class="{ active: item.active }"
            v-for="(item, index) in infoList3"
            :key="index"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="h-button-group" v-if="showZoomBtn">
          <div class="h-button-title">手相识别</div>
          <div class="h-button-list">
            <CheckboxGroup v-model="checkBox" @on-change="checkBoxChange">
              <Checkbox
                :label="item.value"
                v-for="(item, index) in checkBoxList"
                :key="index"
              >
                <i class="icon1 iconfont icon-yanjing"></i>
                <i class="icon2 iconfont icon-yanjing1"></i>
                <span>{{ item.label }}</span>
              </Checkbox>
            </CheckboxGroup>
          </div>
        </div>
      </div>
      <div class="h-center">
        <div class="h-center-body" v-if="infoList3.length">
          <div
            @click="clickItem(item)"
            class="mk"
            :class="[{ active: item.active }, 'infoList-' + index]"
            v-for="(item, index) in infoList3"
            :key="index"
          >
            <div class="mk-title">
              <i class="icon iconfont" :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
            <div class="mk-body" v-if="item.value.length">
              <div class="mk-body-item" :class="{'showMore': showMore, 'isMore': unscrambles.showMore}" v-for="(unscrambles, h_index) in item.value" :key="h_index" >
                <div v-html="unscrambles.text"></div>
                <div class="more-btn" v-if="unscrambles.showMore" @click="showMore = !showMore">
                  <template v-if="!showMore">
                    <Icon type="ios-arrow-down" />
                    <span>展示更多</span>
                  </template>
                  <template v-else>
                    <Icon type="ios-arrow-up" />
                    <span>收起更多</span>
                  </template>
                </div>
              </div>
            </div>
            <empty v-else />
          </div>
        </div>
        <div class="h-center-body" v-else>
          <empty />
        </div>
      </div>
      <div class="h-right">
        <div class="h-right-body">
          <div class="h-right-hender">
            <div class="userinfo2">
              <i class="icon iconfont icon-touxiang1"></i>
              <span class="name">{{ cameraInfo3.info.patientName }}</span>
              <span>{{ cameraInfo3.info.sex }}</span>
              <span>{{ cameraInfo3.info.patientAge }}岁</span>
              <span>手机号:{{ cameraInfo3.info.cellphoneNo }}</span>
            </div>
          </div>
          <div class="h-right-content">
            <div ref="container" id="container">
              <div v-if="systemVersionTooLow" class="systemVersionTooLow">
                <div class="text">系统版本过低</div>
                <div class="text-other">请换windows10或以上的系统</div>
                <Spin v-if="isLogin" class="openApp_loader" fix>
                  <div class="u_loader_k">
                    <div class="u_loader"></div>
                    <img src="@/assets/images/logo.png" alt />
                  </div>
                  <div>手相识别中</div>
                </Spin>
              </div>
            </div>
          </div>
          <div class="footer">
            <template v-if="isPic">
              <template v-if="showZoomBtn && !systemVersionTooLow">
                <div class="icon" @click="showNiceFun">
                  <i v-if="showNice" class="iconfont icon-yanjing"></i>
                  <i v-else class="iconfont icon-yanjing1"></i>
                </div>
                <div class="icon" @click="zoom(1)">
                  <i class="iconfont icon-zoomin"></i>
                </div>
                <div class="icon" @click="zoom(0)">
                  <i class="iconfont icon-zoomout"></i>
                </div>
              </template>
              <div class="icon" @click="photograph">
                <i class="iconfont icon-xiangji3"></i>
              </div>
            </template>

            <div class="icon" @click="poupoPic">
              <i class="iconfont icon-tupian"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Icon @click="closeFun" class="modal_close" type="md-close-circle" />
    <div class="btn_g" v-if="!isPic">
      <div
        class="btn pics"
        @click="poupoPic"
        :style="{ marginLeft: (height * proportion) / 2 + 30 + 'px' }"
      >
        <Tooltip content="解读其他手相照片" placement="bottom">
          <i class="iconfont icon-tupian"></i>
        </Tooltip>
      </div>
    </div>

    <div v-show="!isPic" class="camera_group" :style="style">
      <div class="camera_group_body">
        <camera32
          :width="height * proportion"
          :height="height"
          ref="camera3"
          @showPic="showPic"
          :showWin="!isPic"
          :patientInfo="cameraInfo3.info"
        />
        <div class="jb_group2">
          <div class="jb_top"></div>
          <div class="jb_right"></div>
          <div class="jb_bottom"></div>
          <div class="jb_left"></div>
        </div>
      </div>
    </div>
    <div v-if="showPicList" class="mask" @click="hideMask"></div>
    <div @click.stop class="u_pics_k" :class="{ show: showPicList }">
      <div ref="images" class="u_pics">
        <template v-if="oldPics.length">
          <div
            class="u_pic"
            v-for="(item, index) in oldPics"
            :key="index + '_a'"
          >
            <div class="img_k" @click="clickPic(item)">
              <img v-if="/^data:/.test(item.picUrl)" :src="item.picUrl" />
              <img v-else-if="/^http/.test(item.picUrl)" :src="item.picUrl" />
              <img v-else :src="cloudPic + '/hand/' + item.picUrl" />
            </div>
            <div
              v-if="isToday(item.crDt)"
              @click="delPic(item, index, true)"
              class="del_btn"
            >
              <Icon type="ios-close-circle" />
            </div>
            <i class="date">{{ item.crDt }}</i>
          </div>

          <div id="photograph-btn" class="u_pic">
            <div class="img_k" @click="photograph">
              <Icon type="md-camera" />
              <div class="tip">拍照</div>
            </div>
            <i class="date text-white">.</i>
          </div>
        </template>
        <empty v-else />
      </div>
    </div>
    <Modal
      title="手相拍摄规范"
      width="700"
      footer-hide
      class="handHelp"
      v-model="showHelp"
    >
      <div class="left">
        <img src="@/assets/images/hand/tl.jpg" alt />
      </div>
      <div class="right">
        <div class="item_title">参照左图：</div>
        <div>1、手指微张不并拢，大拇指打开</div>
        <div>2、不佩戴任何物件（例如手表、戒指）</div>
        <div>3、使用慈脉云诊专用手相仪拍照</div>
      </div>
    </Modal>
  </Modal>
</template>

<script>
import camera32 from "./camera3_2.vue";
import Hander from "@/assets/js/Hander2.js";
import { Scene } from "spritejs";
import { mapGetters } from "vuex";
import omljosn from "@/assets/js/OML2CIMAi7.json";
export default {
  components: {
    camera32,
  },
  computed: {
    ...mapGetters(["cameraInfo3", "handData", "handRefresh", "palmDeviceSize"]),
    style() {
      let width = this.height * this.proportion;
      let height = this.height;

      return {
        width: width + 12 + "px",
        height: height + 12 + "px",
        marginTop: -height / 2 + "px",
        marginLeft: -width / 2 + "px",
      };
    },
    infoList3() {
      return this.infoList2.some(i=>i.value) ? this.infoList2 : [];
    },
  },
  watch: {
    cameraInfo3(newValue, oldValue) {
      this.showCamera = this.cameraInfo3.showWin;
      if (newValue.showWin) {
        this.isInfo = false;
        this.isPic = true;
        this.isOpen = false;
        this.getData();
      }
    },
    handData() {
      this.handDataChange();
    },
    handRefresh() {
      this.photograph();
      this.$Message.error("手相识别失败，请重新拍照。请确保手掌掌心朝上平铺于手诊仪底部，五指指尖在拍摄范围内后进行拍照。")
    },
  },
  data() {
    return {
      showMore: false,
      height: 700,
      proportion: 0.75,

      systemVersionTooLow: false, //操作系统版本是否过低
      picUrl: "",
      djs: null,
      showNice: false,
      infoList: [],

      base64: "",
      imageHeight: "",
      imageWidth: "",

      showZoomBtn: false,
      showHelp: false,
      isInfo: false,
      showCamera: false,
      showInfo: true,
      isOpen: false,
      scene: null,
      layer: null,
      isPic: true, //有图
      hander: null,
      showPicList: false,
      oldPics: [],
      isLogin: false,

      checkBox: [],
      checkBoxList: [
        {
          value: 1,
          label: "九丘一平原",
        },
        {
          value: 2,
          label: "三大主线",
        },
        {
          value: 3,
          label: "纹路",
        },
        {
          value: 4,
          label: "斑点",
        },
        {
          value: 5,
          label: "青筋",
        },
        {
          value: 6,
          label: "指节纹",
        },
      ],
      unscrambleList: {},
      colorList: [],
      // 解读来源 1健康 2 婚姻 3 家庭 4 财富 5 事业 6 其他
      infoList2: [
        {
          icon: "icon-xunhuan",
          title: "健康",
          value: [],
          id: 1,
          active: true,
          isShow: false,
        },
        {
          icon: "icon-hunyinzhuangkuangbiangeng",
          title: "婚姻",
          value: [],
          id: 2,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-home1",
          title: "家庭",
          value: [],
          id: 3,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-qianbao",
          title: "财富",
          value: [],
          id: 4,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-renshiyewu",
          title: "事业",
          value: [],
          id: 5,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-qita",
          title: "其他",
          value: [],
          id: 6,
          active: false,
          isShow: false,
        },
      ],
    };
  },
  methods: {
    selectMenuItem(item, index) {
      this.clickItem(item);
      // 滚动到指定位置
      let node = this.$el.querySelector(".infoList-" + index);
      if (node) {
        node.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    clickItem(item) {
      this.infoList2.forEach((i) => {
        i.active = item == i ? true : false;
      });
    },

    checkBoxChange(ids) {
      this.hander.clickBtn(ids);
    },
    hideMask() {
      this.showPicList = false;
      if (!this.layer) {
        this.photograph();
      }
    },
    handDataChange() {
      if (!this.isPic) return;
      if (this.handData.nineHillsPlains.length) {
        this.init();
        this.isPic = true;
        clearTimeout(this.djs);
        this.djs = setTimeout(() => {
          this.isInfo = true;
        }, 100);
        let item = this.oldPics.find((i) => i.aidId == this.aidId);
        if (item) item.handResult = this.handData;
      }
    },
    delPic(item, index, isOld) {
      this.$Modal.confirm({
        title: "提示",
        content: "确定要删除图片吗？",
        onOk: () => {
          let list = isOld ? this.oldPics : this.newPics;
          if (isOld) {
            this.api2("deleteMedicalRecordScan", { aidId: item.aidId }).then(
              (res) => {
                list.splice(index, 1);
                if (!list.length) {
                  // 无图后重新拍照
                  this.photograph();
                  this.showPicList = false;
                  this.infoList = [];
                  this.isInfo = [];
                  //更新子页里的数据
                  this.$store.commit("setPicLabeling", {
                    id: this.cameraInfo3.info.registerCode,
                    value: 0,
                  });
                }
              }
            );
          } else {
            list.splice(index, 1);
          }
        },
        onCancel: () => {},
      });
    },
    
    async clickPic(item) {
      if (this.layer) this.layer.remove();
      this.isOpen = false;
      this.isInfo = false;
      this.showPicList = true;
      this.createHander();
      this.$store.commit("clearHandData");
      this.aidId = item.aidId;

      if (/^data:/.test(item.picUrl)) {
        this.base64 = item.picUrl;
        this.imageHeight = "";
        this.imageWidth = "";
      } else {
        let url = /^http/.test(item.picUrl) ? item.picUrl : (this.cloudPic + "/hand/" + item.picUrl);
        let imgdata = await this.common
          .getImgBase64(url)
          .catch((err) => console.log(err));
        if (!imgdata) {
          this.isLogin = false;
          this.$Message.error("无效图片链接，不能解读");
          if (this.layer) this.layer.remove();
          this.layer = null;
          return;
        }
        this.base64 = imgdata.base64;
        this.imageHeight = imgdata.imageHeight;
        this.imageWidth = imgdata.imageWidth;
      }

      this.api2("getHandUnscrambleByAdiId", {
        aidId: this.aidId, //	明细主键Id
        patientCode: this.cameraInfo3.info.patientCode, //	患者编码
      }).then((res) => {
        res.result.handversion = item.handversion || 1;
        // 测试
        // this.$store.commit("setHandData", omljosn);
        this.$store.commit("setHandData", res.result);
      });
    },

    // 测试
    async clickPic2(item) {
      item.picUrl =
        "https://pic.cimaizhongyi.com/Picture/other/202407/6-20240725173120585.jpg";

      if (this.layer) this.layer.remove();
      this.isOpen = false;
      this.isInfo = false;
      this.showPicList = true;
      this.createHander();
      this.$store.commit("clearHandData");
      this.aidId = item.aidId;

      let imgdata = await this.common
        .getImgBase64(item.picUrl)
        .catch((err) => console.log(err));
      if (!imgdata) {
        this.isLogin = false;
        this.$Message.error("无效图片链接，不能解读");
        if (this.layer) this.layer.remove();
        this.layer = null;
        return;
      }
      this.base64 = imgdata.base64;
      this.imageHeight = imgdata.imageHeight;
      this.imageWidth = imgdata.imageWidth;

      this.$store.commit("setHandData", omljosn);
    },

    isToday(datestr) {
      if (!datestr) return false;
      return (
        this.common.parseTime(new Date(), "{y}-{m}-{d}") ==
        this.common.parseTime(new Date(datestr), "{y}-{m}-{d}")
      );
    },
    getData() {
      let obj = {
        patientCode: this.cameraInfo3.info.patientCode,
        registerCode: this.cameraInfo3.info.registerCode,
        picType: 1,
      };
      this.api2("queryMedicalRecordScan", obj).then((res) => {
        this.oldPics = res.result;
        // 有手相图片直接解读第一张
        if (this.oldPics.length) {
          this.isPic = true;
          this.clickPic(this.oldPics[this.oldPics.length - 1]);
        } else {
          this.isPic = false;
        }

        setTimeout(() => {
          this.scrollIntoView();
        }, 300);

        this.$store.commit("sendLog", {
          remarks: "获取手相历史图片 ",
          apiName: "queryMedicalRecordScan",
          inParams: obj,
          outParams: res,
        });
      });
    },

    scrollIntoView() {
      const element = document.getElementById("photograph-btn");
      if (element) {
        element.scrollIntoView();
      }
    },

    poupoPic() {
      if (!this.oldPics.length) {
        this.$Message.warning("暂无其它手相图片");
      } else {
        this.isPic = true;
        this.showPicList = true;
        this.scrollIntoView();
      }
    },
    plusCamera() {
      var cmr = window.plus.camera.getCamera();
      cmr.captureImage(
        (p) => {
          console.log("成功：" + p);
          window.plus.io.resolveLocalFileSystemURL(
            p,
            (entry) => {
              entry.file(function (file) {
                var fileReader = new plus.io.FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onloadend = function (e) {
                  this.picUrl = e.target.result.toString();
                  console.log(this.picUrl);
                };
              });
            },
            (e) => {
              console.log("读取拍照文件错误：" + e.message);
            }
          );
        },
        (e) => {
          console.log("失败：" + e.message);
        },
        { filename: "_doc/camera/", index: 1 }
      );
    },
    photograph() {
      this.reset();
      if (this.layer) this.layer.remove();
      this.layer = null;
      clearTimeout(this.djs);
      this.showPicList = false;
      this.isPic = false;
      this.isInfo = false;
      this.showZoomBtn = false;
      this.isOpen = false;
      this.$store.commit("clearHandData");
    },
    closeFun() {
      this.cancel();
    },
    cancel() {
      this.isPic = true;
      if (this.$refs.camera3) {
        this.$refs.camera3.closeCamera();
      }
      this.$store.commit("setCameraInfo3", {
        showWin: false,
        info: {},
      });
      if (this.layer) this.layer.remove();
      this.layer = null;
      this.$store.commit("clearHandData");
      this.reset();
    },

    createHander() {
      if (
        [
          "Mac2",
          "Win2000",
          "WinXP",
          "Win2003",
          "WinVista",
          "Win7",
          "win8",
        ].includes(this.common.detectOS())
      ) {
        this.isLogin = true;
        this.systemVersionTooLow = true;
      } else {
        this.systemVersionTooLow = false;
        let container = this.$el.querySelector("#container");
        this.scene = new Scene({
          container,
          mode: "stickyWidth",
        });
        this.layer = this.scene.layer();
        this.hander = new Hander(this.layer);

        this.$store.commit("sendLog", {
          remarks: "实例化 spritejs 画布系统完成",
        });
      }
    },

    init() {
      setTimeout(() => {
        Object.hasOwnProperty.call(this.handData, "texture")
          ? this.formatData2()
          : this.formatData1();
      }, 100);
    },

    // 按指定字段对对象数组分组
    groupBy(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },

    reset() {
      this.unscrambleList = {};
      this.unscrambleColorList = {};
      this.infoList2.forEach((i) => {
        i.value = [];
        i.isShow = false;
      });
    },

    // 新版本格式化数据
    formatData2() {
      this.isLogin = false;
      this.reset();

      let obj = {
        graphical: [], //图形
        texture: [], //纹路
        speckle: [], //斑点
        ninePoint: [], //九区中点和名称
        finger: [], //手指数据
        qingjin: [], // 青筋
        version: 2, // 版本
      };

      // 整理区块名称和区块中心点坐标
      let nine = this.handData.nineHillsPlains;
      obj.ninePoint = nine
        .filter((i) => i.regionName)
        .map((i) => {
          let p = i.graphical.coordinate;
          let point = [
            p.reduce((sum, j) => {
              return (sum += j[0]);
            }, 0) / p.length,
            p.reduce((sum, j) => {
              return (sum += j[1]);
            }, 0) / p.length,
          ];
          return {
            label: i.regionName,
            point,
          };
        });

      this.setPoint2(this.handData, obj);

      obj.threeMainLine = [];
      let handLine = this.handData.handLine;

      // 三大主线
      handLine.forEach((i) => {
        let arr = i.graphical.metadata;

        // 找断点
        let indexs = [];
        arr.forEach((j, index) => {
          if (j.breakPointFlag) {
            indexs.push(index);
          }
        });
        indexs.unshift(0);
        indexs.push(arr.length - 1);
        let arr3 = this.common.chunkArray(indexs, 2); //按长度为2切割数组
        arr3.forEach((i) => {
          let list = arr.slice(i[0], i[1] + 1);
          let points = list
            .filter((j) => j.splitObjIndex == -1)
            .map((i) => i.coordinate);

          obj.threeMainLine.push({
            text: i.lineName,
            points,
          });
        });
      });

      // 分支线
      handLine.forEach((i) => {
        let list = i.graphical.metadata;
        // 1、先找到分叉点，即branchpointflg为true的
        let list2 = list.filter(
          (j) => j.branchPointFlag && j.splitObjIndex == -1
        );
        // 找到所有分叉线上的点，即splitobjindex不等于-1的
        let list3 = list.filter((j) => j.splitObjIndex != -1);
        let g = [];
        list2.filter((i) => {
          let arr = list3.filter(
            (j) => j.parentPointIndex == i.branchPointIndex
          );
          let o = this.groupBy(arr, "splitObjIndex");
          for (const key in o) {
            if (Object.hasOwnProperty.call(o, key)) {
              let l = o[key];
              // l.unshift(i)
              g.push(l);
            }
          }
        });
        g.forEach((j) => {
          let points = j.map((i) => i.coordinate);
          obj.threeMainLine.push({
            text: "",
            points,
          });
        });
      });

      // 青筋
      if (this.handData.palm) {
        this.handData.palm.qingjin.forEach((j) => {
          let str = this.formatUnscramble(j.privateUnscrambles);
          obj.qingjin.push({
            text: str ? "青筋：" + str : "青筋",
            points: j.graphical.coordinate,
          });
        });
      }

      // 添加手指数据
      this.handData.finger.forEach((i) => {
        i.jointWrinkle.forEach((j) => {
          obj.finger.push({
            text: "",
            points: j.graphical.coordinate,
            // isFinger: true, // 是指节纹
          });
        });
      });

      Object.assign(obj, {
        base64: this.base64,
        imageHeight: this.imageHeight || this.palmDeviceSize.width,
        imageWidth: this.imageWidth || this.palmDeviceSize.height,
      });

      if (this.hander) this.hander.setData(obj);
      this.showInfo = true;
      this.showZoomBtn = true;
    },

    // 旧版本格式化数据
    formatData1() {
      this.isLogin = false;

      this.infoList = [];
      let obj = {
        graphical: [], //图形
        texture: [], //纹路
        speckle: [], //斑点
        ninePoint: [], //九区中点和名称
        version: 1, // 版本
      };

      // 整理区块名称和区块中心点坐标
      let nine = this.handData.nineHillsPlains;
      // 合并手指的数据
      // nine = nine.concat(this.handData.finger)

      obj.ninePoint = nine
        .filter((i) => i.graphical)
        .map((i) => {
          let p = i.graphical.coordinate;
          let point = [
            p.reduce((sum, j) => {
              return (sum += j[0]);
            }, 0) / p.length,
            p.reduce((sum, j) => {
              return (sum += j[1]);
            }, 0) / p.length,
          ];
          return {
            label: i.regionName,
            point,
          };
        });

      this.setPoint(nine, obj);
      this.infoList = this.toRepeat(this.infoList);
      this.oldSetInfoList();

      obj.threeMainLine = this.handData.threeMainLine.map((i) => {
        return {
          text: i.lineName,
          points: i.graphical.coordinate,
        };
      });

      Object.assign(obj, {
        base64: this.base64,
        imageHeight: this.imageHeight || this.palmDeviceSize.width,
        imageWidth: this.imageWidth || this.palmDeviceSize.height,
      });

      if (this.hander) this.hander.setData(obj);
      this.showInfo = true;
      this.showZoomBtn = true;
    },

    oldSetInfoList() {
      let arr = [];
      this.infoList.forEach((i) => {
        arr.push(i.label);
        arr.push(i.values);
      });
      let item = this.infoList2.find((i) => i.id == 1);
      item.value = [arr.join("<br/>")];
      item.isShow = true;
      item.value.push({
        text: [
          `<span class="mk-title"><span>掌型:${this.handData.palmName}</span></span>`,
          this.handData.unscramble.replace(/\s*\r\n\s*/g, "</br>"),
        ].join("</br>"),
        showMore: false,
      })
    },

    // 调OML接口
    async getOMLapi(imageInfo) {
      // let file = this.common.dataURLtoFile(imageInfo.base64, "111.jpg");
      // multipartFiles: file,
      this.base64 = imageInfo.base64;
      let picUrl = /^http/.test(imageInfo.picUrl)
        ? imageInfo.picUrl
        : config.intranetPic + imageInfo.picUrl;
      let params = {
        params: {
          picId: imageInfo.aidId,
          apiUrl: config.omlToCimaiApi,
          picUrl,
          userdata: {
            picUrl,
            picId: imageInfo.aidId,
            patientCode: this.cameraInfo3.info.patientCode,
            sex: this.cameraInfo3.info.sex == "男" ? 1 : 2, // 1：男 2：女
          },
        },
      };

      // 保存图片id信息 用于等待获取解析数据用
      this.$store.commit("saveHandPicData", {
        picId: imageInfo.aidId,
        sex: this.cameraInfo3.info.sex == "男" ? 1 : 2, // 1：男 2：女
      });

      let fun = "uploadPictureToOML";
      this.api2(fun, params)
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("sendLog", {
              remarks: "OML接口调用",
              apiName: "uploadPictureToOML",
              outParams: res,
            });
          }, 1000);

          // 检测手机解析数据是否有传过来
          this.$store.commit("OMLSuccess", imageInfo.aidId);
          //测试 实际是用socket消息调用此方法
          // this.$store.commit("setHandData");
        })
        .catch((err) => {
          this.photograph();
        });
    },

    // 保存病历扫描图片信息
    async showPic(imageInfo) {
      this.isPic = true;

      if (imageInfo.debug) {
        this.createHander();
        this.base64 = imageInfo.base64;
        return;
      }

      this.aidId = imageInfo.aidId;
      this.oldPics.push({
        aidId: this.aidId,
        picUrl: imageInfo.base64,
        crDt: this.common.parseTime(new Date(), "{y}-{m}-{d} {h}:{i}"),
      });

      this.getOMLapi({
        aidId: this.aidId,
        base64: imageInfo.base64,
        picUrl: imageInfo.picUrl,
      });

      this.createHander();
    },

    showNiceFun() {
      this.showNice = !this.showNice;
      this.hander.setNiceTipVisibility(this.showNice ? 1 : 0);
    },

    zoom(isEnlarge) {
      let pos = {
        x: this.layer.width / 2,
        y: this.layer.height / 2,
      };
      this.hander.zoom(pos, isEnlarge);
    },
    round(nb) {
      return nb >= this.confidenceProportion
        ? `(置信度${Math.round(nb * 100)}%)`
        : "";
    },

    setUnscrambleList(list) {
      let unscrambleTypeStr = [
        "纹路",
        "斑点",
        "可识别可量化",
        "可识别不可量化",
        "颜色",
        "青筋",
        "脏腑段断裂"
      ];
      if (!list) return;
      list.forEach((i) => {
        let arr = this.unscrambleList[i.unscrambleResource];
        let confidence =
          i.confidence && i.confidence.length
            ? this.round(i.confidence[0])
            : "";
        let obj = {
          unscramble: i.unscramble,
          unscrambleType: i.unscrambleType,
          unscrambleStr: i.unscramble + confidence,
          confidence: i.confidence && i.confidence.length ? i.confidence : 0,
        };
        if(i.unscrambleType == 4){
          let findIndex= this.colorList.findIndex(item=>item.unscramble == obj.unscramble);
          if(findIndex!=-1){
            let item = this.colorList[findIndex]
            if(item.confidence < obj.confidence){
              this.colorList[findIndex] = obj;
            }
          }else{
            this.colorList.push(obj);
          }
        }else{
          if (arr) {
            let findIndex= arr.findIndex(item=>item.unscramble == obj.unscramble);
            if(findIndex!=-1){
              let item = arr[findIndex]
              if(item.confidence < obj.confidence){
                arr[findIndex] = obj;
              }
            }else{
              arr.push(obj);
            }
          } else {
            this.unscrambleList[i.unscrambleResource] = [obj];
          }
        }
      });
    },

    formatUnscramble(list) {
      if (!list) return "";
      let arr = [];
      list.forEach((i, index) => {
        let confidence =
          i.confidence && i.confidence.length
            ? this.round(i.confidence[0])
            : "";
        arr.push(`${index + 1}、${i.unscramble}${confidence}`);
      });
      return arr.join(" ");
    },

    setInfoList() {
      for (const key in this.unscrambleList) {
        if (Object.hasOwnProperty.call(this.unscrambleList, key)) {
          const i = this.unscrambleList[key];
          this.unscrambleList[key] = i.sort(
            (a, b) => b.confidence - a.confidence
          );
        }
      }

      this.infoList2.forEach((i) => {
        if (this.unscrambleList[i.id]) {
          i.value = [{
            text: this.unscrambleList[i.id]
              .map((i, index) => index + 1 + "、" + i.unscrambleStr)
              .join("<br/>"),
            showMore: false,
          }],
          i.isShow = true;
        } else {
          i.isShow = false;
        }
      });

    },

    // 新版数据整理方法
    setPoint2(handData, obj) {
      this.colorList = [];

      // 收集解读信息
      this.setUnscrambleList(handData.jointWrinkleUnscrambles);

      this.setUnscrambleList(handData.mixUnscrambles);

      handData.handLine.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
      });

      handData.finger.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
      });

      handData.nineHillsPlains.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
        if (i.graphical && i.regionCode != "ALL") {
          obj.graphical.push({
            text: i.regionName || "",
            points: i.graphical.coordinate,
          });

          // 收集纹解读数据
          let item = handData.texture.find((j) =>
            j.nineHillsRegion.includes(i.regionCode)
          );
          if (item) {
            item.regionList = item.regionList || [];
            item.regionList.push({
              regionName: i.regionName,
              textureUnscrambles: i.textureUnscrambles,
            });
          }
        }
      });

      this.setInfoList();
      let item = this.infoList2.find((i) => i.id == 1);
      if(handData.palmColorUnscramble){
        item.value.push( 
          {
            text: [
              `<span class="mk-title"><span>掌型颜色解读</span></span>`,
              handData.palmColorUnscramble.replace(/\s*\r*\n\s*/g, "</>"),
            ].join("</br>"),
            showMore: false,
          }
        );
      }
      if(this.colorList.length){
        item.value.push({
          text: this.colorList.map((i, index)=> index + 1 + "、" + i.unscrambleStr).join("</br>"),
          showMore: true,
        });
      }
      if(handData.palmUnscramble){
        item.value.push({
          text: [
            `<span class="mk-title"><span>掌型:${handData.palmName}</span></span>`,
            handData.palmUnscramble.replace(/\s*\r\n\s*/g, "</br>"),
          ].join("</br>"),
          showMore: false,
        });
      }

      // 纹
      handData.texture.forEach((j) => {
        let str = this.formatUnscramble(j.privateUnscrambles);
        let p = j.graphical.coordinate;
        obj.texture.push({
          text: j.textureName + ": " + str,
          points: [p[0], p[2]],
        });
      });

      // 斑点
      handData.speckle.forEach((j) => {
        let str = this.formatUnscramble(j.privateUnscrambles);
        let p = j.graphical.coordinate;
        obj.speckle.push({
          text: (j.unscramble || j.speckleName) + "：" + str,
          // 获取斑点中心点
          points: [
            p[0][0] + (p[1][0] - p[0][0]) / 2,
            p[0][1] + (p[2][1] - p[1][1]) / 2,
          ],
        });
      });
    },

    // 旧版数据整理方法
    setPoint(list, obj) {
      list.forEach((i) => {
        if (i.graphical) {
          obj.graphical.push({
            text: i.regionName || "",
            points: i.graphical.coordinate,
          });
        }

        // 纹
        if (i.texture) {
          i.texture.forEach((j) => {
            if (
              j.graphical.confidence &&
              j.graphical.confidence[0] >= this.confidenceProportion
            ) {
              let confidence = this.round(j.graphical.confidence[0]);
              if (j.unscramble) {
                this.infoList.push({
                  label: i.regionName,
                  value: j.unscramble + " " + confidence, //显视置信度数组
                  values: [],
                });
              }
              obj.texture.push({
                text: (j.unscramble || j.textureName) + " " + confidence,
                points: j.graphical.coordinate,
              });
            }
          });
        }

        // 斑点
        if (i.speckle) {
          i.speckle.forEach((j) => {
            if (j.unscramble) {
              this.infoList.push({
                label: i.regionName,
                value: j.unscramble,
                values: [],
              });
            }

            let p = j.graphical.coordinate;
            obj.speckle.push({
              text: j.unscramble || j.speckleName,
              // 获取斑点中心点
              points: [
                p[0][0] + (p[1][0] - p[0][0]) / 2,
                p[0][1] + (p[1][1] - p[0][1]) / 2,
              ],
            });
          });
        }

        if (i.subRegion && i.subRegion.length) {
          this.setPoint(i.subRegion, obj);
        }
      });
    },
    toRepeat(list) {
      let newList = [];
      list.forEach((i) => {
        let item = newList.find((j) => j.label == i.label);
        let value = i.value.replace(/^\d+、*/, "");
        if (item) {
          if (!item.values.find((x) => x == i.value) && i.value)
            item.values.push(value);
        } else {
          newList.push({
            label: i.label,
            values: [value],
          });
        }
      });

      newList.forEach((i) => {
        i.values = i.values
          .map((j, index) => {
            return (i.values[index] = `${index + 1}、${j}`);
          })
          .join("<br/>");
      });
      return newList;
    },
    setHeight() {
      this.height = document.documentElement.clientHeight - 120;
    },
    handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("Element is visible");
        } else {
          console.log("Element is not visible");
        }
      });
    },
  },
  mounted() {
    this.setHeight();
  },
};
</script>

<style>
@import url("./camera3.css");
</style>
<style lang="scss">
.hand2_con {
  .ivu-modal-mask {
    background: none;
  }
  .ivu-modal-wrap {
    top: 50px;
  }
  .h-body {
    background: #f5f5f5;
    display: flex;
    height: 100%;
    &.hide {
      visibility: hidden;
    }
    .h-left {
      width: 150px;
      height: 100%;
      background: #fff;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      .h-menu {
        height: 100%;
        user-select: none;
        .h-menu-item {
          padding: 15px;
          text-align: center;
          cursor: pointer;
          &.active {
            background: #fbf7f5;
            color: #843a2f;
            position: relative;
            font-weight: bold;
            &::after {
              content: "";
              display: block;
              width: 3px;
              height: 30px;
              background: #843a2f;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 5px;
            }
          }
        }
      }
      .h-title {
        font-weight: bold;
        font-size: 16px;
        padding: 20px 10px 10px;
        color: #333;
        .iconfont {
          padding: 0 10px 0 20px;
          color: #843a2f;
          font-size: 20px;
        }
      }
      .h-button-group {
        margin: 10px;
        background: #fbf9f8;
        padding: 15px;
        user-select: none;
        .h-button-title {
          border-bottom: 1px solid #eee;
          margin-bottom: 5px;
          padding-bottom: 8px;
          font-weight: bold;
          text-align: center;
          color: #333;
        }
        .h-button-list {
          .ivu-checkbox {
            display: none;
          }
          .ivu-checkbox-group-item {
            padding: 5px 0;
            .iconfont {
              padding-right: 5px;
            }
            .icon2 {
              display: none;
            }
            &.ivu-checkbox-wrapper-checked {
              color: #843a2f;
              font-weight: bold;
              .icon2 {
                display: inline-block;
              }
              .icon1 {
                display: none;
              }
            }
          }
        }
      }
    }
    .h-center {
      flex: 1;
      padding: 15px 0 15px 15px;
      height: 100%;
      .h-center-body {
        height: 100%;
        overflow-y: auto;
      }
      .mk {
        padding: 15px;
        border: 1px solid #ede3e1;
        border-radius: 6px;
        margin-bottom: 15px;
        line-height: 2rem;
        &.active {
          background: #ede3e1;
        }
        .mk-title {
          font-weight: bold;
          columns: #222;
          font-size: 16px;
          .icon {
            padding-right: 10px;
            color: #843a2f;
            font-size: 18px;
          }
        }
      }
    }
    .h-right {
      flex: 1;
      padding: 15px;
    }
    .h-right-body,
    .h-center-body {
      height: 100%;
      background: #fff;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 15px;
    }
    .h-right-body {
      display: flex;
      flex-direction: column;
    }
    .h-right-hender {
      padding-bottom: 10px;
    }
    .h-right-content {
      flex: 1;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #eee;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 10px;
        background: #f4f4f4;
        &:hover,
        &.actice {
          background: #843a2f;
          color: #fff;
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
  .userinfo2 {
    .icon {
      color: #843a2f;
      padding-right: 10px;
      font-size: 20px;
    }
    span {
      padding-right: 10px;
    }
    .name {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .modal_close {
    right: 20px;
    top: 20px;
  }
  .mk-body{
    .mk-body-item{
      position: relative;
      &.isMore{
        height: 100px; 
        overflow: hidden;
      }
      &.isMore.showMore{
        height: auto;
        overflow: visible;
        padding-bottom: 60px;
      }
    }
    .more-btn{
      text-align: center;
      font-size: 12px;
      color: #666;
      cursor: pointer;
      // 背景颜色从上到下渐变
      background: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255,.8), rgba(255,255,255,1));
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 40px 0 5px;
      line-height: 20px;
    }
  }
  .active{
    .mk-body{
      .more-btn{
        background: linear-gradient(to bottom, #ede3e100, #ede3e1dd, #ede3e1ff);
      }
    }
  }
}
</style>
