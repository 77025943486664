<template>
  <!-- 症状录入 -->
  <div class="symptom_entry">
    <patient-info-1 v-if="showInfo" :pageType="pageType" :info="info" />

    <div class="symptom_entry_body">
      <div class="ucard">
        <div class="ucard_hander">
          <div class="ucard_title">病历录入</div>
          <div class="ucard_right">
            <Button type="primary" size="small" @click="sendDiseaseToPad" style="margin-right: 10px;">发送到pad</Button>
            <Button :loading="indexSpin.show" @click="submitSymptom" type="primary" size="small">提交病历</Button>
          </div>
        </div>
        <div class="ucard_body">
          <div class="ulist">
            <div class="ulist_item selectList">
              <div class="label">症状<i class="request">*</i></div>
              <div class="value">
                <symptom-select-input placeholder="请选择症状" @submit="selectListChange" v-model="selectList"
                  @input="saveData" :info="info" :etiologyIdObj="etiologyIdObj" />
              </div>
            </div>
            <div class="ulist_item diagnosis1">
              <div class="label">主诉<i class="request">*</i></div>
              <div class="value">
                <chief-complaint @input="saveData" v-model="diagnosisData" :symptomList="selectList"
                  placeholder="请点击选择主诉" />
              </div>
            </div>
            <div class="ulist_item diagnosis2">
              <div class="label">诊断<i class="request">*</i></div>
              <div class="value">
                <Select @on-change="changeDiagnosis" class="table_input diagnosis_select" v-model="diagnosis2"
                  filterable placeholder="请点击输入诊断拼音首字母">
                  <Option v-for="item in diseaseInfos2" :value="item.value" :key="item.value">{{ item.labelAndPy }}
                  </Option>
                </Select>
                <westernMedicineDiseaseName :diagnosis="diagnosis2" />
                <span class="additional cureNumber" v-if="cureNumber">治愈:{{ cureNumber }}人</span>
                <span class="additional">{{ Disease_Type() }}</span>
                <!-- <span class="additional">疗程进度:70%</span> -->
              </div>
            </div>
            <div v-if="info.sex == '女'" class="ulist_item">
              <div class="label">末次月经时间</div>
              <div class="value">
                <DatePicker class="table_input" placement="bottom-start" @on-change="saveData(false)"
                  v-model="menstruationLastTime" type="date" placeholder="末次月经时间">
                </DatePicker>
              </div>
            </div>
            <div class="ulist_item">
              <div class="label">患者自述</div>
              <div class="value">
                <Input class="table_input" @input="saveData" v-model="chiefComplaint"
                  placeholder="若症状库中没有符合的，请点击输入补充症状"></Input>
              </div>
            </div>
            <div class="ulist_item">
              <div class="label">既往史</div>
              <div class="value">
                <symptom-select-input @input="saveData" :filterDtId="pastHistoryDtId" placeholder="请选择既往史"
                  @submit="pastHistoryChange" v-model="pastHistory" :info="info" :etiologyIdObj="etiologyIdObj" />
              </div>
            </div>
            <div class="ulist_item">
              <div class="label">病程初复诊</div>
              <div class="value">
                <symptom-select-input @input="saveData" :filterDtId="initialDiagnosisDtId" placeholder="请选择病程初复诊"
                  @submit="initialDiagnosisChange" v-model="initialDiagnosis" :info="info" :etiologyIdObj="etiologyIdObj" /> 
              </div>
            </div>
            <!-- 
						<div class="ulist_item">
							<div class="label">体格检查</div>
							<div class="value">
								<Input class="table_input" v-model="inspect1" placeholder="请点击输入"></Input>
							</div>
						</div>
						<div class="ulist_item">
							<div class="label">辅助检查</div>
							<div class="value">
								<Input class="table_input" v-model="inspect2" placeholder="请点击输入"></Input>
							</div>
						</div> 
						-->
          </div>
        </div>
      </div>

      <div v-if="showInGroup" class="ucard">
        <div class="ucard_hander">
          <div class="ucard_title">服务患者项</div>
          <div class="ucard_right"></div>
        </div>
        <div class="ucard_body">
          <div class="ulist">
            <div class="ulist_item">
              <div class="label">
                邀约打卡<i v-if="info.patientAge < 60" class="request">*</i>
              </div>
              <div class="value">
                <show-qr-code v-model="inGroup" :info="info" :diseaseType="Disease_Type()" :diagnosis2="diagnosis2"
                  :diagnosisData="diagnosisData" :symptomList="selectList" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ucard">
        <div class="ucard_hander">
          <div class="ucard_title">患者信息补充</div>
          <div class="ucard_right"></div>
        </div>
        <div class="ucard_body">
          <div class="ulist">
            <div class="ulist_item">
              <div class="label">喜好</div>
              <div class="value">
                <hobby v-model="info" placeholder="点击设置喜好" />
              </div>
            </div>
            <div class="ulist_item">
              <div class="label">备注</div>
              <div class="value">
                <remarks-input placeholder="点击输入备注" v-model="info.otherInfo" :patientCode="info.patientCode" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import patientInfo1 from "./patientInfo/patientInfo1.vue";

import { mapGetters } from "vuex";
import Hobby from "./hobby.vue";
import SymptomSelectInput from "./symptomSelectInput.vue";
import ChiefComplaint from "./chiefComplaint.vue";
import RemarksInput from "./remarksInput.vue";
import ShowQrCode from "./showQRCode.vue";
export default {
  components: {
    patientInfo1,
    Hobby,
    SymptomSelectInput,
    ChiefComplaint,
    RemarksInput,
    ShowQrCode,
  },
  props: {
    //页面类型  true:前端医生 false:后端医生
    pageType: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      etiologyPathogenesis: [], //上一次的病因病机
      etiologyIdObj: {},
      cureNumber: 0,
      showInfo: false,
      photoReminder: false, //病历拍照提醒(待就诊)
      pastHistoryDtId: "20043", //既往史类型id
      initialDiagnosisDtId: "20029", //病程初复诊类型尖
      aidiagnostic: "",
      getDiagnosisSwitch: true,
      inspect1: "",
      inspect2: "",
      pastHistory: [],
      initialDiagnosis: [],
      diagnosisData: {
        diagnosis1: "",
        chiefComplaintTime: "",
        chiefComplaintTimeType: "",
        chiefComplaintDegree: "",
      },
      selectList: [],
      diagnosis2: "",
      menstruationLastTime: "",
      chiefComplaint: "",
      inGroup: "", //是否加群
    };
  },
  watch: {
    info() {
      this.init();
    },
    selectList(newValue, oldValue) {
      this.getDiagnosisSwitch = true;
    },
    userSystemConfig: {
      handler: function () {
        this.photoReminder = this.userSystemConfig.mrScanRemindVisit;
      },
      deep: true,
    },
    patientLastSymptom() {
      this.initSelectList();
    },
    diagnosis2() {
      this.getCureNumberByDiseaseId();
    },
    padIsConfirmName(newValue, oldValue) {
      let patientName = newValue.patientName
      let message = '患者' + patientName + '已确认症状'
      if (newValue) {
        this.$Message.success(message);
      }
    },
    yzConfirmData(newValue, oldValue) {
      console.log(newValue)
      if (newValue) {
        this.$Message.success('发送成功，请等待患者确认');
      }
    }
  },
  computed: {
    ...mapGetters([
      "indexSpin",
      "saveCondition",
      "patientLastSymptom",
      "userInfo",
      "diseaseInfos2",
      "diseaseInfos",
      "dicts",
      "userSystemConfig",
      "keyInfo",
      "padIsConfirmName",
      "yzConfirmData"
    ]),
    /**
     * 2、以下情况符合任意一种时，邀约打卡录入项目不显示
     *（1）诊断病种的疗程在1个月以内
     *（2）医馆配置无需医生邀约打卡
     */
    showInGroup() {
      let start = true;
      let list = this.dicts["Disease_Type"] || [];
      let item = list.find((i) => i.value == this.diagnosis2);
      if (item) {
        start = item.string6Unit == "月" && item.string6 >= 1;
      }
      return this.userInfo.inviteType == 1 && start;
    },
  },
  methods: {
    changeDiagnosis() {
      this.saveData();
    },
    getCureNumberByDiseaseId() {
      if (this.diagnosis2) {
        let obj = {
          "diagnosticid2": this.diagnosis2 * 1
        }
        this.api2("getCureNumberByDiseaseId", obj).then(res => {
          this.cureNumber = res.result.cureNumber;
          sessionStorage.setItem("getCureNumberByDiseaseId_" + this.info.registerCode, this.cureNumber)
        })
      } else {
        this.cureNumber = 0
      }
    },
    initSelectList() {
      if (this.patientLastSymptom.isRecall) {
        let obj = this.patientLastSymptom.symptomMain;
        let symptom = this.patientLastSymptom.recallSymptom;
        symptom.forEach(i=>{
          i.etiologyList = [];
        })


        let d = localStorage.getItem("selectList_" + this.info.registerCode);
        if(d){
          this.selectList = JSON.parse(d);
        }else{
          this.selectList = symptom;
        }

        this.pastHistory = symptom.filter(
          (i) => i.dtId == this.pastHistoryDtId
        );
        this.initialDiagnosis = symptom.filter(
          (i) => i.dtId == this.initialDiagnosisDtId
        );

        this.diagnosisData = {
          diagnosis1: obj.diagnosticId1 + "",
          chiefComplaintTime: obj.chiefComplaintTime,
          chiefComplaintTimeType: obj.chiefComplaintTimeType,
          chiefComplaintDegree: obj.chiefComplaintDegree,
        };
        this.menstruationLastTime = obj.menstruationLastTime ? new Date(obj.menstruationLastTime) : "";
        this.chiefComplaint = obj.chiefComplaint;
        this.diagnosis2 = obj.diagnosticId2 + "";
      }
    },
    Disease_Type() {
      let list = this.dicts["Disease_Type"] || [];
      let obj = list.find((i) => i.value == this.diagnosis2);
      if (obj) {
        return obj.string5 ? `疗程：${obj.string5 + obj.string5Unit + "~" + obj.string6 + obj.string6Unit}` : "";
      } else {
        return "";
      }
    },

    // 既往史变化
    pastHistoryChange() {
      this.selectList = this.selectList
        .filter((i) => i.parentId != this.pastHistoryDtId)
        .concat(this.pastHistory);
    },

    // 病程初复诊变化
    initialDiagnosisChange() {
      this.selectList = this.selectList
        .filter((i) => i.parentId != this.initialDiagnosisDtId)
        .concat(this.initialDiagnosis);
    },

    // 症状列表变化
    selectListChange() {
      this.pastHistory = this.selectList.filter(
        (i) => i.parentId == this.pastHistoryDtId
      );
      this.initialDiagnosis = this.selectList.filter(
        (i) => i.parentId == this.initialDiagnosisDtId
      );

      this.getDiagnosisBySymptoms();
    },

    // 通过症状名称集返回中医诊断
    getDiagnosisBySymptoms() {
      if (this.getDiagnosisSwitch) {
        if (!this.selectList.length) return;
        this.getDiagnosisSwitch = false;
        let obj = {
          symptoms: this.selectList.map((i) => i.dsName).join("|"),
          patientAge: this.info.patientAge,
          patientSex: this.info.sex,
          dsIds: this.selectList.map((i) => i.dsId).join("|"),
        };

        return this.api2("getDiagnosisBySymptoms", obj).then((res) => {
          this.$store.commit("sendLog", {
            remarks: "通过症状名称集返回中医诊断",
            inParams: obj,
            outParams: res,
            apiName: "getDiagnosisBySymptoms",
          });

          // if (this.common.isError(res, true)) return;
          let dName = res.result.classification_label;
          let item = this.diseaseInfos.find((i) => i.DiseaseName == dName);

          if (item && this.diagnosis2 != item.DiseaseId) {
            let content = `<p>AI系统诊断为：<span class="t_read">${dName}</span></p><p>您是否引用？</p>`;
            this.$store.commit("setN_tip", {
              onOk: () => {
                this.diagnosis2 = item.DiseaseId; this.aidiagnostic = this.common.clone(item);
                this.saveData();
              },
              okText: "引用",
              title: "",
              content,
            });
          }
        });
      }
    },
    setAcupoint() {
      this.$store.commit("acupoint", {
        // diagnosticName: this.diagnosis2.DiseaseName,
        diagnosticId: this.diagnosis2,
        symptomsIds: this.selectList.map((i) => i.dsId).join("|"),
      });
    },
    // 暂时缓存数据
    saveData() {
      this.setAcupoint();
      let obj = {
        aidiagnostic: "",
        getDiagnosisSwitch: true,
        inspect1: "",
        inspect2: "",
        pastHistory: [],
        initialDiagnosis: [],
        diagnosisData: {
          diagnosis1: "",
          chiefComplaintTime: "",
          chiefComplaintTimeType: "",
          chiefComplaintDegree: "",
        },
        selectList: [],
        diagnosis2: "",
        menstruationLastTime: "",
        chiefComplaint: "",
      };
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          obj[key] = this[key];
        }
      }
      this.$store.commit("saveCondition", {
        key: this.info.registerCode,
        info: obj,
      });
    },
    // 合作医生保存患者症状
    async submitSymptom() {
      if (!this.selectList.length) {
        let node = $(this.$el).find(".selectList .symptom_select_input");
        node.addClass("jzmding");
        setTimeout(function () {
          node.removeClass("jzmding");
        }, 1000);

        this.$Message.warning("请先选择患者的病情【症状】后再提交！");
        return;
      }
      var symptom = [];
      var detailList = [];
      var dsIds = [];
      this.selectList.forEach((i) => {
        detailList.push({
          dtId: i.parentId, //二级症状编码
          dtName: i.parentName, //二级症状名称
          dsId: i.dsId, //症状编码
          dsName: i.dsName, //症状名称
          // 暂时关闭 下个版本发布
          symptomChanges: i.symptomChanges, // 症状变化 0 无 1 加重 2 减轻
        });

        dsIds.push(i.dsId);

        let item = symptom.find((j) => j.dtId == i.parentId);
        if (item) {
          item.details.push({
            dsId: i.dsId,
            dsName: i.dsName,
          });
        } else {
          symptom.push({
            details: [
              {
                dsId: i.dsId,
                dsName: i.dsName,
              },
            ],
            dtId: i.parentId,
            dtName: i.parentName,
          });
        }
      });

      if (!this.diagnosisData.diagnosis1) {
        let node = $(this.$el).find(".diagnosis1 .chief_complaint");
        node.addClass("jzmding");
        setTimeout(function () {
          node.removeClass("jzmding");
        }, 1000);
        this.$Message.warning("【主诉】是一定要选的哦！");
        return;
      }
      if (!this.diagnosis2) {
        let node = $(this.$el).find(".diagnosis2 .table_input");
        node.addClass("jzmding");
        setTimeout(function () {
          node.removeClass("jzmding");
        }, 1000);
        this.$Message.warning("【诊断】是一定要选的哦！");
        return;
      }

      // 患者年龄在60岁以上（含60岁）时，邀约打卡不作为必填项目，没有选择结果也可以提交病历
      if (this.showInGroup && !this.inGroup && this.info.patientAge < 60) {
        let node = $(this.$el).find(".ulist_item .showQRCode");
        node.addClass("jzmding");
        setTimeout(function () {
          node.removeClass("jzmding");
        }, 1000);
        this.$Message.warning("【邀约打卡】是一定要选的哦！");
        return;
      }

      // 是否病历拍照
      if (this.photoReminder) {
        let obj = {
          patientCode: this.info.patientCode,
          registerCode: this.info.registerCode,
          picType: 0,
        };

        this.photoReminder = false;
        let res = await this.api2("queryMedicalRecordScan", obj);
        if (!res.result.length) {
          this.$store.commit("setN_tip", {
            title: "提示",
            content: "病历还没有扫描哦",
          });
          return;
        }
      }

      let diagnosis1 = this.selectList.find(
        (i) => i.dsId == this.diagnosisData.diagnosis1
      );
      let diagnosis2 = this.diseaseInfos2.find(
        (i) => i.value == this.diagnosis2
      );

      var MainData = {
        dsIds, //	症状编码集,格式：555|666|999
        chiefComplaint: this.chiefComplaint, //患者自述
        chiefComplaintDegree: this.diagnosisData.chiefComplaintDegree, //主述程度
        chiefComplaintTime: this.diagnosisData.chiefComplaintTime, //	主诉时间
        chiefComplaintTimeType: this.diagnosisData.chiefComplaintTimeType, //主诉时间类型(0天 1周 2月 3年)
        menstruationLastTime: this.menstruationLastTime
          ? this.common.parseTime(this.menstruationLastTime, "{y}-{m}-{d}")
          : "", //末次月经

        patientCode: this.info.patientCode, //患者编码
        registerCode: this.info.registerCode, //挂号单号
        patientName: this.info.patientName, // 用户名
        cellphoneNo: this.info.cellphoneNo, // 手机号
        fsex: this.info.sex, // 性别
        patientAge: this.info.patientAge, //年龄
        isNoviceGuide: this.info.patientName == "演示患者" ? 1 : 0, //	是否新手指引， 0否 1是
        registerDate: this.info.registerDateTime, //挂号日期
        visitType: this.info.visitType, //就诊类型 1 初诊 2 复诊
        discountRate: this.info.discountRate, //药品折扣率SS

        hospitalCode: this.userInfo.hosptialCode, //医馆编码
        hospitalName: this.userInfo.hosptialName, //医馆名称

        hisdoctorid: this.userInfo.husDoctorId, //his医生ID

        diagnosticid1: diagnosis1 ? diagnosis1.dsId : "", //患者主诉ID
        diagnosticname1: diagnosis1 ? diagnosis1.dsName : "", //患者主诉名

        diagnosticid2: diagnosis2 ? diagnosis2.value : "", //患者主诉ID
        diagnosticname2: diagnosis2 ? diagnosis2.label : "", //患者主诉名

        aidiagnosticid: this.aidiagnostic ? this.aidiagnostic.DiseaseId : "", //	AI
        aiDiagnosticName: this.aidiagnostic
          ? this.aidiagnostic.DiseaseName
          : "", //	医生引用的Python诊断名，若没有引用则空值

        detailList,
        symptom, //症状串
      };

      // 加入加密狗信息
      Object.assign(MainData, this.keyInfo);
      this.postData(MainData);
    },

    postData(obj) {
      this.btnLoading = true;
      this.$store.commit("openSpin");

      this.api2("saveMedicalRecordSymptom", obj)
        .then(async (res) => {
          this.$store.commit("sendLog", {
            remarks: "合作医生保存患者症状",
            apiName: "saveMedicalRecordSymptom",
            inParams: obj,
            outParams: res,
          });
          this.$store.commit("hideIntro");

          this.$Message.success(res.result.msg);

          //未启用排队系统
          if (!config.queueUp) {
            this.$store.commit("closeSpin");

            await this.saveMrEtiologyPathogenesisExplainList(res.result.symptomNo) 
            await this.autoPrescription(res.result.symptomNo);

            // 因socket消息发送会出现丢失的情况导致列表不有正常更新现改为主动请求
            // await this.$store.dispatch("getReloadOrderList");
            this.$router.push("/prescription/" + this.info.registerCode);
          }

          this.savePatientMainSymptom(obj);
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },

    // 批量保存
    saveMrEtiologyPathogenesisExplainList(symptomNo) {
      return new Promise((resolve, reject) => {
        let dialecticsIds = [];
        let arr = [];
        this.selectList.forEach((j) => {
          j.etiologyList.forEach((item) => {
            if(item.value && item.children.length) {
              dialecticsIds.push(item.dialecticsId)
              arr.push({
                symptomNo: symptomNo, // 患者症状号
                patientCode: this.info.patientCode, // 患者编码
                bottomReason: item.children[0].label, // 病因底层原因
                bottomReasonJson: JSON.stringify(
                  item.children.filter((i) => i.label).map((i) => i.label)
                ), // 病因底层原因JSON
                id: item.mrepId || null, // 主键(修改时传入)
                dialecticsId: item.dialecticsId, // 辨证Id
                etiologyId: item.etiologyId, // 病因Id
                pathogenesisId: item.pathogenesisId, // 病机Id
                symptomId: item.symptomId, // 症状Id
                noRelationSymptomName: item.noRelationSymptomName,
                symptomName: item.symptomName,
                isTypical: item.isTypical,
              });
            }
          });
        });

        if (arr.length > 0) {
          this.api2("saveMrEtiologyPathogenesisExplainList", {
            explainDTOS: arr,
          }).then((res) => {
            let strDialecticsId = dialecticsIds.join(",") 
            this.savePatientTreatmentProgress(strDialecticsId, symptomNo);
            resolve(res)
          }).catch(reject)
        }else{
          resolve("没有数据")
        }
      })
    },

    savePatientTreatmentProgress(strDialecticsId, symptomNo) {
      let obj = {
        patientCode: this.info.patientCode, // 病人年龄
        symptomNo: symptomNo, // 患者症状号
        dialecticsIds: strDialecticsId, // 辨证Id字符串,多个用逗号隔开 如11,35,67
        hospitalCode: this.info.hospitalCode, // 医馆编码
      };
      this.$store.commit("openSpin");
      this.api2("savePatientTreatmentProgress", obj)
        .then((res) => {
          this.$store.commit("closeSpin");
          this.$store.commit("setTreatmentProgressList", res.result);
        })
        .finally(() => {
        });
    },

    saveSelectList(){
      localStorage.setItem("selectList_" + this.info.registerCode, JSON.stringify(this.selectList))
    },

    saveSelectList2(){
      let list = this.selectList.filter(i=>i.etiologyList.length && i.etiologyList.some(i=>i.value && i.children.some(k=>k.label)))
      if(list.length){
        localStorage.setItem("selectList_" + this.info.registerCode, JSON.stringify(list))
      }else{
        // 可能存在招回的情况所以将缓存清一下
        localStorage.removeItem("selectList_" + this.info.registerCode)
      }
    },

    // 保存病历信息 用于就诊中页面
    savePatientMainSymptom(obj) {
      this.saveSelectList();

      let list = [];
      let oldData = sessionStorage.getItem("listPatientMainSymptom_" + this.info.registerCode);
      if (oldData) {
        list = JSON.parse(oldData);
      }

      let dsVOList = [];
      let removeDsVOList = [];
      if (list.length && list[0].dsVOList) {
        let dsNames1 = obj.detailList.map(i => i.dsId);
        let dsNames2 = list[0].dsVOList.map(i => i.dsId);
        let diff = this.common.dsNameDiff(dsNames1, dsNames2);
        dsVOList = obj.detailList.map(i => {
          i.isAddDs = diff[1].includes(i.dsId) ? 1 : 0;
          return i;
        })

        removeDsVOList = list[0].dsVOList.filter(i => diff[2].includes(i.dsId));
      } else {
        dsVOList = obj.detailList;
      }

      let cureNumber = sessionStorage.getItem("getCureNumberByDiseaseId_" + this.info.registerCode)

      let newObj = {
        "addedTime": this.common.parseTime(new Date()),
        "chiefComplaint": obj.chiefComplaint,
        "chiefComplaintDegree": obj.chiefComplaintDegree,
        "chiefComplaintTime": obj.chiefComplaintTime,
        "chiefComplaintTimeType": obj.chiefComplaintTimeType,

        "diagnosticDrugsVOList": null,

        "diseaseTypeId": obj.diagnosticid2,
        "diseaseTypeName": obj.diagnosticname2,
        "doctorName": this.userInfo.doctorName,
        "hisDrugsVOList": null,
        "hospitalCode": this.info.hosptialCode,
        "hospitalName": this.info.hosptialName,
        "menstruationLastTime": obj.menstruationLastTime,
        // "patientAge": 38,
        // "patientCode": "1200001",
        // "patientName": "测试",
        // "patientSex": "男",
        // "prescriptionNo": "",
        // "symptomNo": "2307290001",
        "purposeOfConsultationId": obj.diagnosticid1,
        "purposeOfConsultationName": obj.diagnosticname1,
        "sanfangDiagnosticDrugsVOList": null,
        "treatmentProgressList": [],
        "visitDate": this.info.registerDateTime,

        cureNumber,
        dsVOList,
        removeDsVOList,
      }
      list.unshift(newObj);
      sessionStorage.setItem("listPatientMainSymptom_submit_" + this.info.registerCode, JSON.stringify(list));
    },

    // 自动开方
    autoPrescription(symptomNo) {
      return new Promise((resolve, reject) => {
        this.$store.commit("openSpin");
        this.api2("autoPrescription", {
          isNoviceGuide: 0, //	是否新手指引， 0否 1是
          patientCode: this.info.patientCode, //患者编码
          symptomNo, //	症状编号
        }).then((res) => {
          resolve(res)
          this.$store.commit("closeSpin");
          // 测试
          // res.result = {"resultDirectReturn":0,"hundredYearsMapList":{},"resultCode":"1099","resultIsScattered":0,"sanFangMapList":{},"resultMessage":"执行异常!:在将 varchar 值 '50610&0' 转换成数据类型 int 时失败。,ERROR_SEVERITY:16,ERROR_STATE:1"};
          if(res.result.resultCode == '1099'){
            sessionStorage.setItem("autoPrescriptionError_" + this.info.registerCode, res.result.resultMessage);
            this.$Message.error("自动开方出错："+res.result.resultMessage);
          }

          this.$store.commit("saveHundredYearsMapList", {
            list: res.result.isScattered
              ? res.result.sanFangMapList
              : res.result.hundredYearsMapList, //1散方  0百年方
            id: this.info.registerCode,
          });
          this.$store.dispatch("getReloadOrderList");
        }).catch(err=>{
          this.$store.commit("closeSpin");
          reject(err)
        })
      })
    },
    // 发送症状到pad
    sendDiseaseToPad() {
      let cellphoneNo = ''
      if (this.info.cellphoneNo) {
        cellphoneNo = this.info.cellphoneNo.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
      }
      if (this.selectList.length === 0) {
        this.$Message.warning('请先选择患者症状')
        return
      }
      // 发送症状到pad
      let objSend = {
        // symptoms: this.selectList.map((i) => i.dsName).join("|"),
        symptoms: JSON.stringify(this.selectList),
        patientAge: this.info.patientAge,
        patientSex: this.info.sex,
        patientName: this.info.patientName,
        patientCode: this.info.patientCode,
        cellphoneNo: cellphoneNo,
        hospitalCode: this.userInfo.hosptialCode, //医馆编码
        hospitalName: this.userInfo.hosptialName,
        dsIds: this.selectList.map((i) => i.dsId).join("|"),
        diseaseTypeName: this.chiefComplaint
      }
      this.api2("sendingSymptoms", objSend).then((res) => {
        this.$Message.success(res.result)
      })
    },
    setEtiology(){
      this.etiologyIdObj = {};
      let d = localStorage.getItem("selectList_" + this.info.registerCode);
      if(d){
        let list = JSON.parse(d);

        list.forEach(i=>{
          i.etiologyList.forEach(j=>{
            if(j.value){
              this.etiologyIdObj[j.etiologyId] = this.common.clone(j) 
            }
          })
        })
      }

      this.etiologyPathogenesis.forEach(i=>{
        if(i.bottomReason == "[]") return;
        let list =  JSON.parse(i.bottomReason).map(x=>{
          return {
            label: x,
          }
        })
        let obj = this.etiologyIdObj[i.etiologyId] 
        if(obj){
          list.forEach(j=>{
            if(!obj.children.find(x=>x.label == j.label)){
              obj.children.push(j) 
            }
          })
        }else{
          i.children = list
          this.etiologyIdObj[i.etiologyId] = i;
        }
      })
    },
    init() {
      this.listLastTimeEtiologyPathogenesisExplain();

      this.photoReminder = this.userSystemConfig.mrScanRemindVisit;
      this.aidiagnostic = "";
      this.getDiagnosisSwitch = true;
      this.inspect1 = "";
      this.inspect2 = "";
      this.pastHistory = [];
      this.initialDiagnosis = [];
      this.diagnosisData = {
        diagnosis1: "",
        chiefComplaintTime: "",
        chiefComplaintTimeType: "",
        chiefComplaintDegree: "",
      };
      this.selectList = [];
      this.diagnosis2 = "";
      this.menstruationLastTime = "";
      this.chiefComplaint = this.info.chiefComplaint; 
      this.inGroup = "";

      let item = this.saveCondition[this.info.registerCode]; //是否有缓存数据
      if (item) {
        //加载缓存数据
        item = item.info;
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            this[key] = item[key];
          }
        }
      }
      this.$store.dispatch("getPatientLastSymptom", this.info.patientCode);

      this.setAcupoint();
      this.showInfo = true;
    },

    listLastTimeEtiologyPathogenesisExplain() {
      let obj = {
        symptomNo: this.info.symptomNo, // 处方ID
        patientCode: this.info.patientCode, // 处方ID
      };
      this.$store.commit("openSpin");
      this.api2("listLastTimeEtiologyPathogenesisExplain", obj)
        .then((res) => {
          this.$store.commit("closeSpin");
          this.etiologyPathogenesis = res.result;
          this.setEtiology();
        })
    },
  },
  created() {
    this.init();
    this.$store.dispatch("listEtiologyBottomReason");
  },
};
</script>

<style lang="scss">
.symptom_entry {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  .diagnosis_select,
  .western-medicine-disease-name{
    float: left;
    margin-right: 10px;
  }

  .symptom_entry_body {
    padding: 0 15px 15px;
    height: 100%;
    overflow-y: auto;
  }

  .table_input .ivu-input-wrapper {
    margin-bottom: 0;
  }

  .table_input .ivu-date-picker-focused input,
  .table_input .ivu-input:focus {
    box-shadow: none;
  }

  .table_input .ivu-select-selection,
  .table_input .ivu-select-input,
  .table_input .ivu-input {
    padding: 0;
    border: none;
    line-height: 26px;
    height: 26px;
  }

  .diagnosis_select {
    width: 200px;
    min-width: 50px;
  }
}

.ucard {
  margin-bottom: 15px;

  .ucard_hander {
    background: rgba(133, 58, 47, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
    padding: 8px 15px;

    .ucard_title {
      color: #853a2f;
      font-weight: bold;
    }
  }

  .ucard_body {
    border: 1px solid #ddd;
  }
}

.ulist {
  .ulist_item {
    display: flex;
    min-height: 26px;
    line-height: 26px;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: none;
    }

    .label {
      padding: 5px 15px;
      width: 120px;
      border-right: 1px solid #ddd;

      .request {
        color: red;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        padding-left: 5px;
      }
    }

    .value {
      padding: 5px 15px;
      flex: 1;

      .ivu-input-wrapper {
        margin-bottom: 0;
      }

      .additional {
        font-size: 12px;
        background: #fffce0;
        display: inline-block;
        line-height: 16px;
        padding: 4px 8px;
        float: right;
      }

      .cureNumber {
        background: #ffd1b1;
        border-radius: 3px;
      }

      .ivu-date-picker {
        width: 200px;
      }
    }
  }
}
</style>