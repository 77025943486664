<template>
  <div class="patient-reedback">
    <template v-if="showWin">
      <div class="left">
        <userlist ref="userlistRef" @change="handlechange" />
      </div>
      <div class="center">
        <userFeedback
          v-if="activeItem.patientCode"
          :info="activeItem"
          @success="successFeedback"
        />
        <empty v-else />
      </div>
      <div class="right">
        <div class="right-title">就诊记录</div>
        <tab2 v-if="activeItem.patientCode" :info="activeItem" />
        <empty v-else />
      </div>
    </template>
    <empty v-else text="暂无权限" />
  </div>
</template>

<script>
import { hasAuth } from "@/utils/uEdition.js";
import userlist from "./com/userlist.vue";
import tab2 from "../successCase/com/tab2.vue";
import userFeedback from "./com/userFeedback.vue";
export default {
  components: {
    userlist,
    tab2,
    userFeedback,
  },
  data() {
    return {
      activeItem: {},
      showWin: false,
    };
  },
  methods: {
    handlechange(item) {
      this.activeItem = item;
    },
    successFeedback() {
      this.$refs.userlistRef.changeCount();
    },
  },
  created() {
    this.showWin = hasAuth("YZ-12");
  },
};
</script>

<style lang="scss" scoped>
.patient-reedback {
  padding: 10px 15px;
  display: flex;
  height: 100%;
  & > div {
    height: 100%;
    background: #fff;
    margin-right: 10px;
    border-radius: 5px;
    padding: 10px;
  }
  .left {
    width: 200px;
  }
  .center {
    flex: 1;
  }
  .right {
    width: 400px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .right-title {
      font-weight: bold;
      font-size: 15px;
      padding: 5px 0 0 15px;
    }
  }
  .empty{
    width: 100%;
  }
}
</style>

