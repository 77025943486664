import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

import index from '@/views/index/index.vue'
import login from '@/views/login/index.vue'
import condition from '@/views/condition/index.vue'
import prescription from '@/views/prescription/index.vue'
import prescription_zj from '@/views/prescription_zj/index.vue'
import patientSearch from '@/views/patientSearch/index.vue'
import myQuestion from '@/views/myQuestion/index.vue'
import medicalPractise from '@/views/medicalPractise/index.vue'
import examinationSymptom from '@/views/examinationSymptom/index.vue'
import examinationPrescription from '@/views/examinationPrescription/index.vue'
import examinationPrescriptionError from '@/views/examinationPrescriptionError/index.vue'
import examinationSymptomError from '@/views/examinationSymptomError/index.vue'
import schoolReport from '@/views/schoolReport/index.vue'
import successCase from '@/views/successCase/index.vue'
import diseaseEnter from '@/views/diseaseEnter/index.vue'
import patientFeedback from '@/views/patientFeedback/index.vue'

Vue.use(Router)

export default new Router({
    routes: [{
            path: '/',
            component: login
        },
        {
            path: '/index',
            component: index
        },
        {
            path: '/patientFeedback',
            name: 'otherPage',
            component: patientFeedback 
        },
        {
            path: '/medicalPractise',
            name: 'otherPage',
            component: medicalPractise
        },
        {
            path: '/successCase',
            name: 'otherPage',
            component: successCase
        },
        {
            path: '/schoolReport/:type*',
            name: 'otherPage',
            component: schoolReport,
            props: true,
        },
        {
            path: '/examinationSymptom/:type',
            name: 'otherPage',
            component: examinationSymptom,
            props: true,
        },
        {
            path: '/examinationPrescriptionError',
            name: 'otherPage',
            component: examinationPrescriptionError,
        },
        {
            path: '/examinationSymptomError',
            name: 'otherPage',
            component: examinationSymptomError,
        },
        {
            path: '/examinationPrescription/:type',
            name: 'otherPage',
            component: examinationPrescription,
            props: true,
        },
        {
            path: '/myQuestion',
            name: 'otherPage',
            component: myQuestion
        },
        {
            path: '/patientSearch',
            name: 'patientSearch',
            component: patientSearch,
        },
        {
            path: '/prescription_zj/:id',
            component: prescription_zj,
            name: 'prescription_zj',
            props: true
        },
        {
            path: '/prescription/:id',
            component: prescription,
            props: true,
            name: 'prescription',
        },
        {
            path: '/condition/:id',
            component: condition,
            name: 'condition',
            props: true
        },
        {
            path: '/diseaseEnter',
            component: diseaseEnter,
            name: 'diseaseEnter',
            props: true
        },
    ]
})