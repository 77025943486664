<!-- 页头 -->

<template>
  <div class="floatFoot">
    <Header>
      <Menu mode="horizontal" theme="dark" active-name="1">
        <div class="menu_left">
          <div class="layout-logo" :class="versionType"></div>
          <div class="h_hospita_name" v-if="userInfo.hosptialName">
            {{ userInfo.hosptialName }}
          </div>
          <div
            v-if="userInfo.doctorName"
            style="
              display: inline-block;
              color: #fff;
              padding-left: 20px;
              opacity: 0.9;
            "
          >
            我们是中医行业的良心和先驱
          </div>
        </div>
        <!--请联系医学院进行排班后接诊-->
        <div v-if="userInfo.husDoctorId" class="layout-nav u_nav">
          <!-- 合作医生用导航 -->
          <template v-if="!expert">
            <MenuItem
              name="home"
              class="u_tab"
              :class="{ u_active: navActive == 'index' }"
            >
              <router-link to="/index">
                <Icon type="home"></Icon>首页
              </router-link>
            </MenuItem>
            <MenuItem
              name="djz"
              class="u_tab"
              :class="{
                u_active: [
                  'prescription_zj',
                  'prescription',
                  'condition',
                ].includes(navActive),
              }"
            >
              <div @click="clickVisit">
                <a>
                  <Badge :count="visitList.length">
                    <Icon type="android-time"></Icon>就诊
                  </Badge>
                </a>
              </div>
            </MenuItem>
            <MenuItem
              name="jzls"
              class="u_tab"
              :class="{
                u_active:
                  navActive == 'historyList' ||
                  navActive == 'patientSearch' ||
                  navActive == 'medicalRecord',
              }"
            >
              <router-link to="/patientSearch">
                <Icon type="ios-calendar-outline" />患者
              </router-link>
            </MenuItem>
            <!-- <MenuItem v-if="userInfo.hosptialType" name="gh" class="u_gh"> -->
            <MenuItem v-if="showReg" name="gh" class="u_gh">
              <div @click="setRegForm(true)" class="ivu-dropdown-rel">
                <Icon type="ios-calendar-outline" />挂号
              </div>
            </MenuItem>
          </template>

          <!-- 专家页信息 -->

          <template v-else>
            <MenuItem
              name="home"
              class="u_tab"
              :class="{ u_active: navActive == 'index' }"
            >
              <a @click="menuClick('/index')">
                <Icon type="home"></Icon>首页
              </a>
            </MenuItem>
            <template>
              <MenuItem
                name="jzz"
                class="u_tab"
                :class="{
                  u_active: [
                    'prescription_zj',
                    'prescription',
                    'condition',
                  ].includes(navActive),
                }"
              >
                <Dropdown placement="bottom">
                  <Badge :count="visiting_zj.visitNum">
                    <a @click="jumpZj">
                      <Icon type="ios-calendar-outline"></Icon>开方中
                    </a>
                  </Badge>
                </Dropdown>
              </MenuItem>
            </template>

            <MenuItem
              name="jzls"
              class="u_tab"
              :class="{
                u_active:
                  navActive == 'historyList' ||
                  navActive == 'patientSearch' ||
                  navActive == 'medicalRecord',
              }"
            >
              <Dropdown placement="bottom">
                <a @click="menuClick('/historyList')">
                  <Icon type="ios-calendar-outline"></Icon>就诊历史
                </a>
                <DropdownMenu slot="list">
                  <DropdownItem>
                    <a @click="menuClick('/historyList')">
                      <Icon type="md-clipboard"></Icon>就诊史查询
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </MenuItem>

            <MenuItem
              name="gh"
              class="u_gh"
              :class="{ qg_disabled: !visiting_zj.noVisitNum }"
            >
              <div @click="addDoctorRushBill" class="ivu-dropdown-rel">
                <Badge :count="visiting_zj.noVisitNum">
                  <Icon type="ios-calendar-outline"></Icon>接诊
                </Badge>
              </div>
            </MenuItem>
          </template>

          <!-- <successCase /> -->
          <MenuItem
            v-uEdition="'YZ-10'"
            name="gh"
            class="u_gh success_case"
            v-show="
              [
                'successCase',
                'prescription_zj',
                'prescription',
                'index',
                'condition',
                'patientSearch',
                'historyList',
              ].includes(navActive)
            "
          >
            <div @click="menuClick('/successCase')" class="ivu-dropdown-rel">
              <Icon type="ios-calendar-outline"></Icon>成功病案
            </div>
          </MenuItem>

          <MenuItem
            v-uEdition="'YZ-12'"
            name="gh"
            class="u_gh success_case"
            v-show="
              [
                'successCase',
                'prescription_zj',
                'prescription',
                'index',
                'condition',
                'patientSearch',
                'historyList',
                'patientFeedback',
              ].includes(navActive)
            "
          >
            <!-- :class="{ u_active: navActive == 'patientFeedback' }" -->
            <div
              @click="menuClick('/patientFeedback')"
              class="ivu-dropdown-rel"
            >
              <Badge :count="messageCount">患者反馈</Badge>
            </div>
          </MenuItem>

          <MenuItem name="yh" class="u_user">
            <Dropdown trigger="click" placement="bottom">
              <a>
                <Icon type="md-person" />
                {{ userInfo.doctorName }}
              </a>
              <DropdownMenu slot="list">
                <!-- <DropdownItem>
                  <a @click="openVideo">
                    <Icon type="ios-videocam" />引导视频
                  </a>
                      </DropdownItem>-->
                <DropdownItem>
                  <a @click="openWin">
                    <Icon type="ios-help-circle" />问题反馈
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a @click="editPwskg = true">
                    <Icon type="md-lock" />密码修改
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a @click="noticeModal = true">
                    <Icon type="md-help" />新功能
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a @click="openSetInfoWin">
                    <Icon type="md-settings" />设置
                  </a>
                </DropdownItem>
                <DropdownItem divided v-if="isPadEnv">
                  <a class="switch-mode">
                    医生看诊模式
                    <i-switch
                      v-model="modeChecked"
                      true-color="#af312d"
                      false-color="#D8D8D8"
                      @on-change="switchHandle"
                    />
                  </a>
                </DropdownItem>
                <DropdownItem divided>
                  <a @click="logOut"> <Icon type="md-exit" />退出 </a>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </MenuItem>
          <MenuItem v-if="u_notice.content" name="gh" class="u_gh">
            <div @click="$store.commit('openMsage')" class="ivu-dropdown-rel">
              <div class="gongao">
                <i class="iconfont icon-tongzhi1"></i>
              </div>
            </div>
          </MenuItem>
        </div>
        <div v-else class="layout-nav u_nav">
          <MenuItem v-if="u_notice.content" name="gh" class="u_gh">
            <div @click="$store.commit('openMsage')" class="ivu-dropdown-rel">
              <div class="gongao">
                <i class="iconfont icon-tongzhi1"></i>
              </div>
            </div>
          </MenuItem>
        </div>
        <win-change v-if="isElectron" />
        <!-- <win-change />  -->
      </Menu>

      <!-- 
      引导视频
      <div v-if="video" class="video_mk">
        <div @click="video=false" class="v_close">
          <i class="iconfont icon-close"></i>
        </div>
        <video controls="controls" :src="videourl"></video>
            </div>-->
      <!-- 修改密码 -->
      <Modal :closable="false" v-model="editPwskg" title="密码修改">
        <Form
          ref="editPws"
          :model="editPws"
          :label-width="0"
          :rules="editPwsRule"
          class="password-edit"
        >
          <FormItem prop="oldpasswd">
            <Input
              v-model="editPws.oldpasswd"
              type="password"
              placeholder="输入旧密码"
            ></Input>
          </FormItem>
          <!-- <FormItem prop="passwd">
            <Input
              v-model="editPws.passwd"
              type="password"
              placeholder="输入新密码"
            ></Input>
                </FormItem> -->
          <FormItem prop="passwd">
            <passwordInput v-model="editPws.passwd" placeholder="输入新密码" />
          </FormItem>
          <FormItem prop="passwdAgain">
            <Input
              @on-enter="submitForm('editPws')"
              v-model="editPws.passwdAgain"
              type="password"
              placeholder="再次输入新密码"
            ></Input>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button type="default" @click="editPwskg = false">取消</Button>
          <Button type="info" @click="submitForm('editPws')">提交</Button>
        </div>
      </Modal>

      <!-- 挂号 -->
      <Modal
        class="register"
        :mask-closable="false"
        :closable="false"
        v-model="regForm"
        title="挂号"
      >
        <Form ref="patient" :model="patient" :label-width="80" :rules="rules1">
          <FormItem prop="patientName" label="姓名">
            <AutoComplete
              class="patientName"
              @on-search="setSearchList2"
              @on-select="changeValue1"
              v-model="patient.patientName"
              placeholder="请输入输入姓名"
            >
              <Option
                v-for="item in patientList"
                :value="item.cellphone + '_' + item.patientName"
                :key="item.patientCode"
              >
                <div class="name">{{ item.patientName }}</div>
                <div class="phone">{{ item.cellphone }}</div>
                <div class="age">{{ item.patientAge }}</div>
                <div class="gender">{{ item.gender == "F" ? "女" : "男" }}</div>
              </Option>
            </AutoComplete>
          </FormItem>

          <FormItem prop="cellphoneNo" label="手机号">
            <AutoComplete
              @on-search="setSearchList"
              class="patient_phone"
              @on-select="changeValue1"
              v-model="patient.cellphoneNo"
              placeholder="请输入手机号"
            >
              <Option
                v-for="item in patientList"
                :value="item.cellphone + '_' + item.patientName"
                :key="item.patientCode"
              >
                <div class="name">{{ item.patientName }}</div>
                <div class="phone">{{ item.cellphone }}</div>
                <div class="age">{{ item.patientAge }}</div>
                <div class="gender">{{ item.gender == "F" ? "女" : "男" }}</div>
              </Option>
            </AutoComplete>
          </FormItem>

          <FormItem prop="patientAge" label="年龄">
            <Input v-model="patient.patientAge" placeholder="年龄">
              <span slot="append">岁</span>
            </Input>
          </FormItem>

          <FormItem
            class="month"
            prop="birthMonth"
            v-if="patient.patientAge && patient.patientAge <= 1"
            label="月份"
          >
            <Input
              v-model="patient.birthMonth"
              placeholder="未满一周岁请填写月份"
            >
              <span slot="append">月</span>
            </Input>
          </FormItem>

          <FormItem label="性别">
            <RadioGroup class="ivu-input" v-model="patient.gender">
              <Radio label="女"></Radio>
              <Radio label="男"></Radio>
            </RadioGroup>
          </FormItem>
          <FormItem class="gh_hf" label="婚姻状况">
            <RadioGroup class="ivu-input" v-model="patient.maritalStatus">
              <Radio label="未婚"></Radio>
              <Radio label="已婚"></Radio>
              <Radio label="离异"></Radio>
              <Radio label="丧偶"></Radio>
              <!-- <Radio label="未知"></Radio> -->
            </RadioGroup>
          </FormItem>
          <FormItem label="地址">
            <Input
              @on-enter="submitReg('patient')"
              v-model="patient.address"
              placeholder="地址"
            ></Input>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button @click="setRegForm(false)">关闭</Button>
          <Button
            :loading="submitRegLoading"
            html-type="submit"
            class="reg_ok_btn"
            type="info"
            @click="submitReg('patient')"
            >保存</Button
          >
        </div>
      </Modal>

      <!-- 处方笺弹出框 -->
      <medicalRecord
        v-model="medicalRecordOpenWin"
        @close="printClose"
        :info="prescriptionNo"
      />

      <!-- 悬浮入口 -->
      <div v-if="!expert" class="siderbar" :class="{ s_hide: s_heid }">
        <div class="s_btn" @click="s_heid = !s_heid">
          <Icon v-if="!s_heid" type="ios-arrow-forward" />
          <Icon v-else type="ios-arrow-back" />
        </div>
        <div class="s_head"></div>
        <div class="s_body">
          <div class="s_item" v-for="item in siderbar" :key="item.label">
            <div
              @dblclick.prevent
              @click.stop="clickSiderbar(item)"
              class="z_dh"
            >
              {{ item.label }}
              <i class="tl"></i>
              <i class="tr"></i>
              <i class="bl"></i>
              <i class="br"></i>
            </div>
            <ul v-if="item.child" class="z_dh_g">
              <li
                @dblclick.prevent
                @click.stop="clickSiderbar(z_item)"
                v-for="z_item in item.child"
                :key="z_item.label"
              >
                {{ z_item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="s_foot"></div>
      </div>

      <!--使用反馈弹窗-->
      <Modal
        footer-hide
        class="pSearch feedback_k"
        v-model="win"
        ok-text="提交问题"
        title="使用反馈"
        @on-ok="tj"
        @on-cancel="win = false"
      >
        <div class="wrap">
          <div class="wrapper">
            <div class="title">问题描述</div>
            <div class="question">
              <span>*</span>
              <div v-if="hide" class="wzts">
                说说我在使用慈脉云诊时的问题（最少5个字）
              </div>
              <textarea
                v-model="question"
                @focus="focus"
                @blur="blur"
                class="text"
                type="text"
                placeholder
              ></textarea>
            </div>
            <div class="up-pic">
              <div class="i-title">
                <i class="icon">
                  <img src="../assets/images/camera.png" />
                </i>
                <span class="upload">上传问题照片</span>
              </div>
              <div class="add-pic">
                <div class="tu_g">
                  <div
                    class="picitem"
                    v-for="(item, index) in picList"
                    @click="del(item)"
                    :style="stytlePic(item)"
                    :key="index"
                  >
                    <Icon type="ios-close" />
                  </div>
                  <div
                    v-show="picList.length < 10"
                    id="filePicker"
                    @click="clickFile"
                  >
                    <input
                      @change="fileChange"
                      type="file"
                      id="file"
                      multiple="multiple"
                      name="myfile"
                      ref="file"
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="title">联系电话</div>
            <div class="p-number">
              <span>*</span>
              <input v-model="cellPhone" type name />
            </div>
          </div>
          <div class="ivu-modal-footer">
            <button
              @click="win = false"
              type="button"
              class="ivu-btn ivu-btn-text"
            >
              <span>取消</span>
            </button>
            <button @click="tj" type="button" class="ivu-btn ivu-btn-primary">
              <span>提交问题</span>
            </button>
          </div>
        </div>
      </Modal>

      <!--系统问题提交成功-->
      <Modal
        footer-hide
        :mask-closable="false"
        :closable="false"
        class="tj_success"
        v-model="feedback_win"
        width="364"
      >
        <div class="su-wrap">
          <div class="su-wrapper">
            <div class="success">提交成功!</div>

            <div class="check" @click="feedback_win = false">
              <a href="myQuestion">查看提问</a>
            </div>

            <div class="close">
              <Button type="text" @click="feedback_win = false">X</Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal class="setInfoWin" v-model="setInfoWin" title="设置" footer-hide>
        <div class="item">
          <!-- <span>病历扫描提醒(待就诊)</span> -->
          <span>病历扫描提醒(待就诊)</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.mrScanRemindVisit"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div class="item">
          <span>病历扫描提醒(就诊中)</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.mrScanRemindVisited"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div class="item">
          <span>是否打印处方笺</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.printPrescription"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div class="item">
          <span>用户反馈单</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.userFeedback"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div class="item">
          <span>手相设备</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.handDeviceVersion"
          >
            <span slot="open">2.0</span>
            <span slot="close">1.0</span>
          </i-switch>
        </div>
        <div class="item" v-if="userInfo.isSkipMRA">
          <span>选病因</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.selectEtiology"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div class="item">
          <span>选症状后是否必选病因</span>
          <i-switch
            @on-change="setInfoChange"
            v-model="userSystemConfigForm.mustSelectEtiology"
          >
            <span slot="open">开</span>
            <span slot="close">关</span>
          </i-switch>
        </div>
        <div style="color: red; text-align: center" v-if="version">
          慈脉云诊{{ version }}
        </div>
      </Modal>
    </Header>
    <notice
      v-if="onLogin"
      v-model="noticeModal"
      noticeType="2"
      :userId="userInfo.husDoctorId"
    />
    <capabilityIndicators v-if="onLogin && !expert" />

    <!-- 处方打印 -->
    <prescriptionPrint
      v-model="medicalRecordPringWin.show"
      :drugList="medicalRecordPringWin.drugList"
      :info="medicalRecordPringWin.info"
      :quantity="medicalRecordPringWin.quantity"
      hide
    />
  </div>
</template>

<script>
import u_common from "../common";
import capabilityIndicators from "./capabilityIndicators.vue";
import { mapGetters, mapMutations } from "vuex";
import WinChange from "./winChange.vue";
import isPad from "@/utils/isPad.js";
export default {
  components: {
    // successCase,
    capabilityIndicators,
    WinChange,
  },
  data() {
    // 修改密码验证
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (this.common.checkStrong(value) != 4) {
        callback(new Error("密码必须包括8位以上数字、大小写字母和特殊符号"));
      } else {
        if (this.editPws.passwdAgain !== "") {
          this.$refs.editPws.validateField("passwdAgain");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editPws.passwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isPadEnv: isPad(),
      modeChecked: true,
      setInfoWin: false,
      userSystemConfigForm: {},
      submitRegLoading: false,
      djz_filter: "",
      jzz_filter: "",
      noticeModal: false,
      video: false,
      videourl: "",
      siderbar: [
        //悬浮导航数据
        {
          label: "使用反馈",
          type: "fk",
        },
        {
          label: "症状学习",
          url: "/medicalPractise",
        },
        {
          label: "百年方",
          child: [
            {
              label: "百年方模拟练习",
              url: "/examinationPrescription/practice",
            },
            {
              label: "百年方考核",
              url: "/examinationPrescription/formal",
            },
          ],
        },
        {
          label: "云诊病历",
          child: [
            {
              label: "慈脉云诊病历模拟练习",
              url: "/examinationSymptom/practice",
            },
            {
              label: "慈脉云诊病历考核",
              url: "/examinationSymptom/formal",
            },
          ],
        },
        {
          label: "我的错题",
          url: "",
          child: [
            {
              label: "百年方错题",
              url: "/examinationPrescriptionError",
            },
            {
              label: "病历错题",
              url: "/examinationSymptomError",
            },
          ],
        },
        {
          label: "查看成绩",
          child: [
            {
              label: "百年方考核成绩",
              url: "/schoolReport/prescription",
            },
            {
              label: "慈脉云诊病历考核成绩",
              url: "/schoolReport/symptom",
            },
          ],
        },
      ],
      hide: true,
      win: false,
      // spin_show: false,

      editPws: {
        oldpasswd: "",
        passwd: "",
        passwdAgain: "",
      },
      editPwsRule: {
        oldpasswd: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        passwd: [
          { validator: validatePass, trigger: "blur" },
          // {
          //   type: "string",
          //   min: 8,
          //   message: "密码长度不能小于8位",
          //   trigger: "blur",
          // },
        ],
        passwdAgain: [{ validator: validatePass2, trigger: "blur" }],
      },

      patient: {
        patientCode: "", //患者编码
        patientName: "", //患者姓名
        patientAge: "", //年龄
        birthMonth: "", //月份
        maritalStatus: "未婚", //婚姻状况(1未婚,2已婚,3离异 4丧偶 5未知)
        gender: "女", //性别(F女 M男)
        cellphoneNo: "", //手机号
        address: "", //地址
      },
      rules1: {
        cellphoneNo: [
          {
            required: true,
            message: "请输入正确的电话号码",
            pattern: /^1\d{10}$/,
            trigger: "blur",
          },
        ],
        patientName: [
          {
            required: true,
            message: "请输入姓名",
            pattern: /^.{2,}$/,
            trigger: "blur",
          },
        ],
        patientAge: [
          {
            required: true,
            message: "请输入正确的年龄",
            pattern: /^\d{1,3}$/,
            trigger: "blur",
          },
        ],
        birthMonth: [
          {
            required: true,
            message: "请输入正确的月数",
            pattern: /^\d{1,2}$/,
            trigger: "blur",
          },
        ],
      },

      // 是不显视演示版字样
      question: "",
      cellPhone: "",

      // PageIndex:'',
      picList: [],
      xhr: "",
      reader: new FileReader(),
      jsq: 0,
      formData: null,
      files: "",
      fileIndex: 0,
      feedback_win: false, //问题反馈成功开关
      s_heid: true, //左侧悬浮开关

      editPwskg: false,

      u_introStart: false,
      medicalRecordOpenWin: false,
    };
  },
  computed: {
    ...mapGetters([
      "messageCount",
      "medicalRecordPringWin",
      "version",
      "userSystemConfig",
      "regForm", // 挂号窗口开关
      "patientList", // 挂号患者搜索列表
      "userInfo", // 用户信息
      "prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
      "navActive", // 导航高亮显视
      "menuActive", // 患者高亮显视
      "expert", // 是否专家
      "onLogin", // 是否在线
      "visiting_zj", // 当前开放中的患者信息
      "medicalRecordOpen", //打开打印弹窗
      "prescriptionNo", //处方ID
      "u_notice", //通知内容
      "introStart", //是否开启新手引导
      "token",
      "visitList",
      // doctorName : "好医生"
    ]),
    showReg() {
      return (
        this.userInfo.hosptialType ||
        (config.internalHospitalDisplayRegistration &&
          config.internalHospitalDisplayRegistration.includes(
            this.userInfo.doctorName
          ))
      );
    },
    noVisits() {
      let list = this.prescribeRegisterList.NoVisit.details;
      return this.djz_filter
        ? list.filter((i) => {
            i.pinyinCode = i.pinyinCode ? i.pinyinCode.toUpperCase() : "";
            return (
              (i.patientName + i.pinyinCode).indexOf(
                this.djz_filter.toUpperCase()
              ) != -1
            );
          })
        : list;
    },
    visits() {
      let list = this.prescribeRegisterList.Visit.details;
      return this.jzz_filter
        ? list.filter((i) => {
            i.pinyinCode = i.pinyinCode ? i.pinyinCode.toUpperCase() : "";
            return (
              (i.patientName + i.pinyinCode).indexOf(
                this.jzz_filter.toUpperCase()
              ) != -1
            );
          })
        : list;
    },
  },
  methods: {
    ...mapMutations(["setRegForm", "logOut", "closeFeedback", "hideIntro"]),
    clickVisit() {
      if (this.visitList.length) {
        this.$store.commit("clickVisit");
      } else {
        this.$Message.info("暂无就诊患者");
      }
    },
    openSetInfoWin() {
      this.setInfoWin = true;
      this.userSystemConfigForm = this.common.clone(this.userSystemConfig);
    },
    setInfoChange() {
      this.$store
        .dispatch("saveUserSystemConfig", this.userSystemConfigForm)
        .then((res) => {
          this.$store.commit("setUserSystemConfig", this.userSystemConfigForm);
        });
    },
    jumpZj() {
      if (this.visiting_zj.visitNum) {
        let url = "/prescription_zj/" + this.visiting_zj.patient.registerCode;
        this.$router.push(url);
      }
    },
    showCameraFun(item, typeId) {
      let fun = typeId ? "setCameraInfo3" : "setCameraInfo2";
      this.$store.commit(fun, {
        info: item,
        typeId,
        showWin: true,
      });
    },
    clearValue() {
      setTimeout(() => {
        this.djz_filter = "";
        this.jzz_filter = "";
      }, 500);
    },
    openVideo() {
      this.video = true;
    },
    clickSiderbar(item) {
      if (item.type) {
        if (item.type == "fk") this.openWin();
      } else {
        if (item.url) this.$router.push(item.url);
      }
    },
    openWin() {
      this.win = true;
      this.cellPhone = this.userInfo.fMobileNumber;
    },
    tj() {
      if (!this.question || !this.cellPhone) {
        this.$Message.error(
          "您的反馈对我们来说非常重要，请您务必将手机号及问题描述认真填写后提交。"
        );
        return;
      }
      this.UpladFile();
      this.win = false;
    },
    blur() {
      if (!this.question) this.hide = true;
    },
    focus() {
      this.hide = false;
    },
    //关闭打印框
    printClose() {
      this.$store.commit("setMedicalRecordOpen", false);
      this.$router.push("/index");
      this.$store.commit("hideIntro");
      localStorage.removeItem("introStart"); //删除引导标示
      this.$store.commit("setisNoviceGuide", 1);
    },
    // 专家菜单点击
    menuClick(url) {
      if (this.visiting_zj.visitNum) {
        this.$Message.error("请先开方！");
      } else {
        this.$router.push(url);
      }
    },
    // 抢单
    addDoctorRushBill() {
      if (this.visiting_zj.noVisitNum) {
        this.$store.dispatch("addDoctorRushBill");
      } else {
        this.$Message.info("无单可接");
      }
    },
    // 设置挂号患者搜索列表
    setSearchList(value) {
      if (value.length >= 6) {
        this.$store.dispatch("getPatientList", {
          cellphoneNo: value,
          nameOrPinyin: "",
        });
      }
    },
    // 设置挂号患者搜索列表
    setSearchList2(value) {
      if (value.length >= 2) {
        this.$store.dispatch("getPatientList", {
          cellphoneNo: "",
          nameOrPinyin: value.trim(),
        });
      }
    },
    // 挂号患者搜索值改变时
    changeValue1(value) {
      if (!value) return;
      var self = this;
      var u_arr = value.split("_");
      var hz = self.patientList.filter(
        (y) => y.cellphone == u_arr[0] && y.patientName == u_arr[1]
      );

      if (hz.length) {
        hz = hz[0];
        var Status = "";
        switch (hz.maritalStatus) {
          case "5":
            Status = "未知";
            break;
          case "4":
            Status = "丧偶";
            break;
          case "3":
            Status = "离异";
            break;
          case "2":
            Status = "已婚";
            break;
          case "1":
            Status = "未婚";
            break;
        }
        self.patient.patientCode = hz.patientCode;
        self.patient.patientName = hz.patientName;
        self.patient.patientAge = hz.patientAge + "";
        self.patient.maritalStatus = Status;
        self.patient.gender = hz.gender == "F" ? "女" : "男";
        self.patient.cellphoneNo = hz.cellphone;
        self.patient.address = hz.address;
        self.patient.birthMonth = hz.birthMonth;
        $(".reg_ok_btn").focus();
      }
      self.$store.commit("cliearPatientList");
      var js = 2;
      //解决自动清空的问题
      var u_djs = setInterval(() => {
        self.patient.patientName = hz.patientName;
        self.patient.cellphoneNo = hz.cellphone;
        if (!js--) clearInterval(u_djs);
      }, 100);
      this.$refs["patient"].validate();
    },
    // 密码修改
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var newData = {
            oldPassWord: this.editPws.oldpasswd,
            newPassWord: this.editPws.passwd,
          };
          this.api2("modifyPassWord", newData).then((res) => {
            if (this.common.isError(res)) return;
            this.editPwskg = false;
            this.$store.commit("logOut");
          });
        } else {
          this.$Message.error("用户信息输入不正确，请重新输入！");
        }
      });
    },
    submitReg(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var newData = this.common.clone(this.patient);
          var Status = "";
          switch (newData.maritalStatus) {
            case "未知":
              Status = 5;
              break;
            case "丧偶":
              Status = 4;
              break;
            case "离异":
              Status = 3;
              break;
            case "已婚":
              Status = 2;
              break;
            case "未婚":
              Status = 1;
              break;
          }
          // 判断是事重复挂号
          if (this.isRepeatRegister()) {
            this.$Message.info("待就诊列表里已存在此患者");
            this.setRegForm(false);
            return;
          }

          newData.maritalStatus = Status;
          newData.gender = newData.gender == "女" ? "F" : "M";
          this.$store.commit("setRegister_dq", newData);
          this.submitRegLoading = true;
          this.api2("getAddRegister", newData)
            .then(async (res) => {
              await this.$store.dispatch("getReloadOrderList");
              this.setRegForm(false);
            })
            .finally(() => {
              this.submitRegLoading = false;
            });
        } else {
          this.$Message.error("用户信息输入不正确，请重新输入！");
        }
      });
    },
    //判断是事重复挂号
    isRepeatRegister() {
      let isRepate = false;
      let list = this.prescribeRegisterList.NoVisit.details;
      if (list.length) {
        // patient.cellphoneNo patientName
        let patient = list.find(
          (i) =>
            i.patientName == this.patient.patientName &&
            i.cellphoneNo == this.patient.cellphoneNo
        );
        if (patient) {
          isRepate = true;
          this.$router.push("/condition/" + patient.registerCode);
        }
      }
      return isRepate;
    },

    // 上传图片用
    stytlePic(item) {
      return { "background-image": "url(" + item + ")" };
    },
    del(item) {
      this.$refs.file.value = "";
      this.picList.splice(this.picList.indexOf(item), 1);
    },
    clickFile() {
      this.$refs.file.click();
    },
    fileChange(e) {
      this.fileIndex = 0;
      this.files = e.target.files;
      this.fileReader();
    },
    fileReader() {
      this.reader.readAsDataURL(this.files[this.fileIndex]);
      this.fileIndex++;
    },
    photoCompress(file, w, objDiv) {
      var ready = new FileReader();
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file);
      ready.onload = function (e) {
        var re = e.target.result;
        this.canvasDataURL(re, w, objDiv);
      }.bind(this);
    },
    canvasDataURL(path, obj, callback) {
      var _this = this;
      var img = new Image();
      img.src = path;
      img.onload = function () {
        var that = this;
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        var quality = 0.7; // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        // 创建属性节点
        var anw = document.createAttribute("width");
        anw.nodeValue = w;
        var anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality;
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL("image/jpeg", quality);
        // 回调函数返回base64的值
        callback(base64);
      };
    },
    /**
     * 将以base64的图片url数据转换为Blob
     * @param urlData
     * 用url方式表示的base64图片数据
     */
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //上传文件方法
    UpladFile() {
      var _this = this;
      _this.formData = new FormData(); // FormData 对象
      if (_this.picList.length) {
        _this.picList.forEach((i, index) => {
          _this.canvasDataURL(i, { quality: 0.2 }, function (base64) {
            var bl = _this.convertBase64UrlToBlob(base64);
            _this.jsq++;
            _this.formData.append(
              // "multipartFiles" + _this.jsq,
              "multipartFiles",
              bl,
              "file_" + Date.parse(new Date()) + _this.jsq + ".jpg"
            ); // 文件对象
            if (_this.jsq >= _this.picList.length)
              _this.xmlHttp(_this.formData);
          });
        });
      } else {
        _this.xmlHttp(_this.formData);
      }
    },
    xmlHttp(form) {
      this.win = false;
      var url = config.newapi + "/user/addUserQuestion"; // 接收上传文件的后台地址
      var tempPara = {
        question: this.question,
        cellPhone: this.cellPhone,
      };
      for (const key in tempPara) {
        if (tempPara.hasOwnProperty(key)) {
          const element = tempPara[key];
          form.append(key, JSON.stringify(element));
        }
      }
      // form.append("userdata", JSON.stringify(tempPara));
      this.xhr = new XMLHttpRequest(); // XMLHttpRequest 对象
      this.xhr.open("post", url, true); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
      this.xhr.setRequestHeader("Authorization", this.token[0]);
      this.xhr.onload = this.uploadComplete; //请求完成
      this.xhr.onerror = this.uploadFailed; //请求失败
      this.xhr.send(form); //开始上传，发送form数据
    },
    //上传成功响应
    uploadComplete(evt) {
      var data = JSON.parse(evt.target.responseText);
      if (data.success) {
        this.picList = [];
        this.question = "";
        this.feedback_win = true;
      } else {
        this.$Message.error(data.meta.msg);
      }
    },
    //上传失败
    uploadFailed(evt) {
      this.$Message.error("上传失败！");
    },
    //取消上传
    cancleUploadFile() {
      this.xhr.abort();
    },
    set_u_introStart() {
      if (location.hash == "#/index") {
        this.u_introStart = this.introStart;
      }
    },
    switchHandle(status) {
      if (!status) {
        setTimeout(() => {
          localStorage.setItem("notPadModeStatus", false);
          this.$store.commit("setShowPadPage", false);
          this.$router.push("/diseaseEnter");
        }, 200);
      }
    },
  },
  watch: {
    medicalRecordOpen() {
      this.medicalRecordOpenWin = this.medicalRecordOpen;
    },
    // 打开挂号窗口时初始化数据
    regForm(value) {
      this.patient = {
        patientCode: "", //患者编码
        patientName: "", //患者姓名
        patientAge: "", //年龄
        maritalStatus: "未婚", //婚姻状况(1未婚,2已婚,3离异 4丧偶 5未知)
        gender: "女", //性别(F女 M男)
        cellphoneNo: "", //手机号
        address: "", //地址
      };
      // this.spin_show = false;
    },
    introStart(value) {
      this.set_u_introStart();
    },
  },
  created() {
    this.medicalRecordOpenWin = this.medicalRecordOpen;
    setTimeout(
      function () {
        this.$store.commit("openMsage");
      }.bind(this),
      1000
    );
  },
  mounted: function () {
    this.set_u_introStart();
    // this.videourl = this.expert ? config.videourl2 : config.videourl;
    var self = this;
    $(".ivu-modal").delegate("input", "keyup", function (e) {
      if (e.keyCode == 13) {
        u_common.nextInput(e);
      }
    });

    // 文件base64化，以便获知图片原始尺寸
    this.reader.onload = function (e) {
      this.picList.push(e.target.result);
      if (this.files.length > this.fileIndex) this.fileReader();
    }.bind(this);
  },
};
</script>
